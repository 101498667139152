<template>
  <div>
    <div class="outTopBox">
      <top-bread :bread="bread" class="bread">
        <span slot="second">酒店管理</span>
        <span slot="third">{{ pageInfo.text }}酒店</span>
      </top-bread>
      <fold-top-search @fold="getFoldMeth" v-if="$route.path.indexOf('onePage')==-1"></fold-top-search>
    </div>
    <el-card class="box-card searchCard" v-show="fold.showSearch">
      <top-search :topSearchShow="topSearchShow" @condition="getCondition" @setDefaultQuickDateCur="setDefaultQuickDateCur" :topSearchSave="topSearchSave" :allLoadingState="allLoadingState" ref="topSearch" :isQuickDateCur="true"></top-search>
    </el-card>
    <screen-type @screenTypeSearch="screenTypeSearch" v-if="auth.type !== 'group'" :showPartProp="showPart" :pageInfo="pageInfo" :allLoadingState="allLoadingState"></screen-type>
    <el-card class="box-card" v-loading="showLoading" v-if="showFunModel('数据概览')">
      <div class=" rowBox vertical">
        <div class="titleBox">
          <div class="title blueTitle">数据概览</div>
        </div>
        <el-row :gutter="10" style="margin-top:20px;">
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.hotelNum">
            <span slot="numTitle">酒店数量</span>
            <span slot="unit">家</span>
            <span slot="time">{{ showDataTime('end') }}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.roomNum">
            <span slot="numTitle">客房数量</span>
            <span slot="unit">间</span>
            <span slot="time">{{ showDataTime('end') }}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" :span="spanCom" :parShowDate="showData.activeTerminalNum">
            <span slot="numTitle">激活终端数量</span>
            <span slot="unit">台</span>
            <span slot="time">{{ showDataTime('end') }}</span>
          </index-four-row-num-unit>
          <index-four-row-num-unit :type="type" v-if="pageInfo.hotelState !== 'del'" :span="spanCom" :parShowDate="showData.dayActiveRate" parShowUnit="money">
            <span slot="numTitle">{{ showRateText }}</span>
            <span slot="unit">%</span>
            <span slot="time">{{ showDataTime("all") }}</span>
          </index-four-row-num-unit>
        </el-row>
      </div>
    </el-card>
    <div v-if="showline">
      <el-card class="box-card" v-if="
          showFunModel('数据走势') &&
            pageInfo.hotelState !== 'newList' &&
            pageInfo.hotelState !== 'del'
        ">
        <div class="lineContent chart">
          <div class="outChartBox" v-loading="lineLoading">
            <one-yaxis-line-chart v-if="lineChart.flag && !lineChart.noData" :chart="lineChart"></one-yaxis-line-chart>
          </div>
          <div v-if="lineChart.flag && lineChart.noData" style="line-height:500px;text-align:center">
            暂无数据
          </div>
        </div>
      </el-card>
    </div>
    <el-card class="box-card" v-if="showFunModel('列表')">
      <div class="quickDateBox">
        <div class="title blueTitle">酒店列表</div>
      </div>
      <el-row class="btnRow">
        <el-button type="success" @click="goLink" v-if="curCompanyName == '希尔顿惠庭'">批量更新</el-button>
        <el-button type="danger" @click="operateConfirmMeth('del', '删除')" size="medium" class="largeBtn" v-if="showFunModel('删除')">删除</el-button>
        <el-button type="danger" @click="operateConfirmMeth('recover', '恢复')" size="medium" class="largeBtn" v-if="showFunModel('申请恢复')">恢复</el-button>
        <!--聚屏导出-->
        <!--所有酒店列表导出-->
        <!--        <el-button type="warning" @click="getExportList()" class="largeBtn" v-if="showFunModel('导出') && pageInfo.hotelState==='all'">导出</el-button>-->
        <!--		根据筛选条件导出		-->
        <export-btn :exportUrlProp="global.exportAllHotel" :conditionProp="condition" parentProp="allHotel" v-if="showFunModel('导出') && pageInfo.hotelState === 'all'" text-prop="实时导出"></export-btn>
        <el-button @click="operateConfirmMeth('adv', '广告下架')" size="medium" class="largeBtn" v-if="showFunModel('广告下架') && pageInfo.hotelState === 'all'">广告下架</el-button>
        <!-- v-if="showFunModel('MAC解绑') && pageInfo.hotelState === 'all'" -->
        <el-button @click="macBoundDia = true" size="medium" v-if="showFunModel('MAC解绑')" class="largeBtn">MAC解绑</el-button>
        <!--  -->
        <el-button @click="LBBoundDia = true" size="medium" v-if="showFunModel('乐播授权查询')" class="largeBtn">乐播授权查询</el-button>
        <el-button :disabled="operateIds.split(',').length > 1" @click="operateConfirmMeth('editMac', '修改')" size="medium" class="largeBtn" v-if="showFunModel('修改MAC地址') && pageInfo.hotelState === 'all'">修改MAC地址</el-button>
        <div class="rightBtn">
          <el-button type="primary" @click="newBuild" size="medium" class="largeBtn" v-if="showFunModel('新建')">新建</el-button>
          <export-btn :exportUrlProp="global.exportCreateHotel" :conditionProp="condition" parentProp="hotelManNewList" v-if="showFunModel('导出') && pageInfo.hotelState === 'newList'"></export-btn>
          <el-button type="success" @click="dialogExport.dialog = true" size="medium" class="largeBtn" v-if="showFunModel('聚屏酒店导入')">聚屏酒店导入</el-button>
          <export-btn :exportUrlProp="global.exportScreenHotel" :conditionProp="condition" parentProp="screenHotel" textProp="聚屏商导出" v-if="
              showFunModel('聚屏商导出') && pageInfo.hotelState === 'newList'
            "></export-btn>
          <!--<exportBootTime :exportUrlProp="global.exportBootTime" :conditionProp="condition" parentProp="BootTime" v-if="pageInfo.hotelState==='all'" class="boot_time" id="boot_time"></exportBootTime>-->
          <exportBootTime :exportUrlProp="global.exportBootTime" :conditionProp="condition" parentProp="BootTime" v-if="pageInfo.hotelState === 'all' && behaviorExportShow" class="boot_time" id="boot_time"></exportBootTime>
        </div>
      </el-row>
      <table-list :table="table" ref="tablePar" @tableMeth="tableMeth" @showTableLoading="showTableLoading" @page="getNewPage" @showUploadDialog="showUploadDialog" @limit="getNewLimit" @tableSelect="getTableSelect" v-loading="table.loading" :topSearchSave="topSearchSave"></table-list>
    </el-card>

    <el-card class="box-card" v-if="false">
      <el-form :model="editCodeForm" :rules="editCodeRules" ref="editCodeForm" style="width:400px">
        <el-form-item prop="event">
          <el-select class="fullSelect" v-model="editCodeForm.event" clearable collapse-tags placeholder="事件类型">
            <el-option v-for="item in selectData.editCodeEvent" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="editCodeForm.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="editCodeForm.code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="editCodeConfirmMeth">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog title="导入" :visible.sync="dialogExport.dialog" :close-on-click-modal="false" width="60%">
      <el-steps :active="importData.active" align-center class="blueStep">
        <el-step title="上传Excel"></el-step>
        <el-step title="数据预览"></el-step>
        <el-step title="导入数据"></el-step>
      </el-steps>
      <div class="container">
        <div class="first stepItem" v-if="importData.active === 0">
          <div class="uploadBox " v-if="importData.active === 0">
            <el-upload class="upload-demo" drag accept=".xlsx" :action="global.upload" :headers="headers" :on-success="uploadSuccess" :before-upload="beforeUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="80" status="warning" v-if="importData.active === 0 && importData.oneProgress === 1"></el-progress>
          <div class="notice" v-if="importData.active === 0 && importData.oneProgress === 0">
            <div class="title">*Excel文件请符合以下标准</div>
            <div class="item">
              请按照模板格式导入
              <span class="downLoad"><a href="./static/template.xlsx" download="">下载导入模板</a></span>
            </div>
            <div class="item">后缀名需为xls或者xlsx</div>
            <div class="item">数据请勿放在合并的单元格中</div>
            <div class="item">单次导入条数最大不可超过100条</div>
          </div>
        </div>
        <div class="two stepItem" v-if="importData.active === 1">
          <div class="top paddingBox">预览数据：{{ importData.fileName }}</div>
          <div class="showCon">
            <table-list :table="importTable" v-loading="importData.loading"></table-list>
          </div>
          <div class="bottom">
            <div class="show paddingBox">
              <span>信息补全：{{ importData.infoComplete }}</span>
              <span class="infoIncomplete">信息不完整：{{ importData.infoIncomplete }}</span>
            </div>
            <div>
              酒店信息填写不完整会导致部分功能无法正常使用。可选择重新编辑文件后再次导入或导入后及时在系统中补全酒店信息。
            </div>
          </div>
        </div>
        <div class="three stepItem" v-if="importData.active === 2" v-loading="importData.loading">
          <!--<el-progress :text-inside="true" :stroke-width="20" :percentage="importData.percentage" status="exception"></el-progress>-->
          <!--<div class="dataShow">-->
          <!--<span>准备导入数据：{{importData.readyCount}}</span>-->
          <!--<span>成功：{{importData.successCount}}</span>-->
          <!--<span class="error">失败：{{importData.errorCount}}</span>-->
          <!--</div>-->
        </div>
        <div class="four stepItem" v-if="importData.active === 3">
          <div class="iconBox">
            <i class="el-icon-success"></i>
          </div>
          <div class="notice">
            数据导入完成，已导入{{ importData.successCount }}条数据，失败{{
              importData.errorCount
            }}条数据
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelMeth" :class="importData.active === 3 ? 'none' : ''">取 消</el-button>
        <el-button type="primary" @click="next" v-if="importData.active > 0 && importData.active < 3">下一步</el-button>
        <el-button type="primary" @click="next" v-if="importData.active === 3">完成</el-button>
      </div>
    </el-dialog>
    <div class="allLoading" v-show="false">{{ allLoadingStateCom }}</div>
    <el-dialog title="修改MAC地址" :close-on-click-modal="false" class="coopDialog" :visible.sync="editMacDialog.visible" width="550px">
      <el-form :model="editMacDialog" ref="editMacForm" :rules="editMacRules">
        <el-form-item prop="mac" label="原MAC地址">
          <el-input v-model="editMacDialog.mac"></el-input>
        </el-form-item>
        <el-form-item prop="newMac" class="noMarginBottom" label="新MAC地址">
          <el-input v-model="editMacDialog.newMac"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogCancel">取 消</el-button>
        <el-button type="primary" @click="editMacMath">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导出下载" :visible.sync="exportDialog">
      <el-table :data="exportData">
        <el-table-column property="date" label="时间" width="200"></el-table-column>
        <el-table-column property="filename" label="文件名"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button type="warning" @click="exportHotel(scope.row.filepath)" class="largeBtn">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="addLogTitle" :close-on-click-modal="false" class="coopDialog" :visible.sync="addBackstageVisible" width="550px">
      <el-form :model="addBackstageForm" ref="addBackstageForm" :rules="addBackstageFormRules" label-width='100px'>
        <el-form-item prop="hotel_alias" label="分组名称">
          <el-input v-model="addBackstageForm.hotel_alias"></el-input>
        </el-form-item>
        <el-form-item v-if="backstageId==''" prop="template_id" class="noMarginBottom" label="选择模板">
          <el-input v-model="selectedHotel.label"></el-input>
          <el-button style="margin-left:20px" type="primary" @click="showTemplateDialog">选择</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addBackstageFasle">取 消</el-button>
        <el-button type="primary" @click="addBackstageTrue">确 定</el-button>
      </div>
    </el-dialog>

    <hotelAllContent ref="hotelAllContent" v-if="path=='/onePage/HotelManAll'" :topInfoLoading="topLoading" :query="query"></hotelAllContent>
    <systemDrawer ref="systemDrawer" :query="query"></systemDrawer>
    <remoteMaintenance ref="remoteMaintenance" :query="query"></remoteMaintenance>
    <!-- mac解绑弹窗 -->
    <MacUnbundling :visible.sync="macBoundDia"></MacUnbundling>
    <LBUnbundling :visible.sync="LBBoundDia"></LBUnbundling>
    <ehotel-dialog ref="ehotelDialog" @getSelectedHotel="getSelectedHotel" :selectedHotelCheck="selectedHotel">
    </ehotel-dialog>
  </div>
</template>
<script>
import hotelAllContent from "./hotelAllContent";
import qs from "querystring";
import GLOBAL from "../../../api/globalVar.js";
import commonMeth from "../../../api/method";
import exportBootTime from "../exportBootTime";
import LBUnbundling from "../../operation/MacUnbundling/LBSearch.vue";
import MacUnbundling from "../../operation/MacUnbundling/index.vue";
import {
  addHotelGroup,
  getHotelGroup,
  editHotelSub,
} from "@/api/hotelGroup.js";
export default {
  name: "hotelManAl",
  props: ["pageInfo"],
  components: {
    exportBootTime,
    LBUnbundling,
    hotelAllContent,
    MacUnbundling,
  },
  data: () => ({
    path: "",
    macBoundDia: false,
    LBBoundDia: false,
    showline: false,
    accountType: "",
    TypeSearch: true,
    getIndexHeader: {
      partnerType: "",
      accountId: "",
    },
    curCompanyName: "",
    type: "timeCenter",
    bread: {
      level: 3,
      firstPath: { path: "/indexShow" },
      thirdPath: { path: "/hotelManAl" },
      fourthPath: { path: "/hotelManAl" },
    },
    topAttr: {
      terminal: "",
      terminalOption: [
        { label: "country1", value: "1" },
        { label: "country2", value: "2" },
        { label: "country3", value: "3" },
      ],
    },
    table: {
      tableHeader: [
        { prop: "hotel", label: "酒店名称", width: "350" },
        { prop: "hotel_brand", label: "集团品牌", width: "" },
        { prop: "hotel_use_state", label: "客户终端状态", width: "" },
        { prop: "h_province", label: "省份", width: "110" },
        { prop: "h_city", label: "城市", width: "110" },
        { prop: "h_county", label: "区县", width: "110" },
        { prop: "hotel_room", label: "客房总量（间）", width: "" },
        { prop: "n_connected", label: "激活终端数量（台）", width: "" },
        { prop: "addtime", label: "上线时间", width: "180" },
      ],
      tableList: [],
      page: 1,
      limit: 20, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAl",
      name: "hotelManAl",
      otherFromUrl: "hotelManAl",
      option: true,
      select: true,
      loading: true,
      specialCell: {
        BD: true,
        patternBtn: false,
        build_person: true,
        systemBtn: false,
        instructionBtn: false,
        addBackstage: true,
        jumpBtn: true,
        editName: true,
        dar: false,
        darText: "日活率（%）",
      },
    },
    form: {
      name: "",
      region: "",
      date1: "",
      date2: "",
      delivery: false,
      type: [],
      resource: "",
      desc: "",
    },
    formLabelWidth: "120px",
    dialogExport: {
      dialog: false,
    },
    importData: {
      active: 0,
      oneProgress: 0,
      percentage: 40,
      fileName: "",
      infoComplete: "",
      infoIncomplete: "",
      readyCount: "",
      successCount: "",
      errorCount: "",
      file: "",
      filePath: "",
      loading: true,
    },
    topSearchShow: {},
    condition: {
      hotelName: "",
      hotelContract: "",
      startTime: "",
      endTime: "",
      hotelCreateTimeStart: "",
      hotelCreateTimeEnd: "",
      screenType: "",
      screenTypeUndefined: "",
      acceptanceType: "",
      hasChildren: "",
    },
    token: "",
    auth_type: "",
    today: "",
    importTable: {
      //没有区
      tableHeader: [
        { prop: "province", label: "省", width: "80" },
        { prop: "city", label: "城市", width: "80" },
        { prop: "county", label: "区", width: "80" },
        { prop: "lng", label: "经度", width: "" },
        { prop: "lat", label: "纬度", width: "" },
        { prop: "name", label: "酒店名称", width: "" },
        { prop: "address", label: "酒店地址", width: "" },
        { prop: "contact_name", label: "酒店联系人", width: "" },
        { prop: "contact_phone", label: "联系人电话", width: "" },
        { prop: "room_num", label: "房间数量（间）", width: "" },
        { prop: "box_num", label: "终端数量（台）", width: "" },
        { prop: "ota_price", label: "ota价格（元）", width: "" },
        { prop: "ota_hotel_level", label: "酒店标签", width: "" },
      ],
      tableList: [
        {
          hotel: 11111,
          hotel_brand: "11111",
          hotel_use_state: "11111",
          h_province: "11111",
        },
        {
          hotel: 22222,
          hotel_brand: "22222",
          hotel_use_state: "22222",
          h_province: "22222",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
        {
          hotel: 33333,
          hotel_brand: "33333",
          hotel_use_state: "33333",
          h_province: "33333",
        },
      ],
      page: 1,
      limit: 5, //当前每页显示多少条
      total: 0,
      fromUrl: "hotelManAssign",
      pageShow: false,
    },
    headers: {
      token: "",
      Authorization: "",
    },
    cache_key: "",
    auth: {},
    showData: {
      hotelNum: 0,
      roomNum: 0,
      activeTerminalNum: 0,
      dayActiveRate: 0,
    },
    showLoading: true,
    fold: {
      showSearch: false,
      foldText: "展开筛选项",
    },
    belongPartnerCodeAllStr: "",
    allotPartnerCodeAllStr: "",
    funArr: [],
    allData: {},
    topSearchSave: {
      // hotel_brand:['2','3','1'],
      // BD:[189,187],
      // province:[
      //     // {label:'内蒙古自治区',value:'15'},
      //     {label:'河北省',value:'13'},
      // ],
      // city: {label: "保定市",value: "1306"},
      // country: {label: "满城区",value: "130607"},
      // keyword:'测试09-15',
      // hotel_use_state_tem:[
      //     {name:'待上线',value:6}
      // ],
      // coopType:'screen',
      // partner_id:["f6c142ebfe9b438fa442d44f25238b93", "HCC000106"],
      // coopPartnerType:'belongPartnerCode',
    },
    quickDateCur: "",
    curDate: {
      yesDay: "",
      curDay: "",
      startWeek: "",
      endWeek: "",
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
    },
    operateIds: "",
    hotelContract: "",
    lineLoading: false,
    lineChart: {
      id: "lineChart",
      showTitle: true,
      flag: false,
      noData: false,
      fromUrl: "hotelManAl",
      lineLoading: false,
      option: {
        legend: [],
        xData: [],
        series: [
          // {
          //     name: '激活终端数量',
          //     type: 'line',
          //     smooth:true,
          //     itemStyle: {
          //         normal: {
          //             color: GLOBAL.chartColor[1], //改变折线点的颜色
          //             lineStyle: {
          //                 color: GLOBAL.chartColor[1]//改变折线颜色
          //             }
          //         }
          //     },
          //     data: []
          // },
        ],
      },
    },
    showPart: {},
    allLoadingState: false,
    editCodeForm: {
      event: "",
      mobile: "",
      code: "",
    },
    editCodeRules: {
      event: [
        { required: true, message: "请选择修改事件类型", trigger: "blur" },
      ],
      mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
    },
    selectData: {
      editCodeEvent: [
        { value: "login", name: "登录" },
        { value: "register", name: "注册" },
      ],
    },
    editMacDialog: {
      visible: false,
      mac: "",
      newMac: "",
    },
    editMacRules: {
      mac: [{ required: true, message: "请输入原mac地址", trigger: "blur" }],
      newMac: [{ required: true, message: "请输入新mac地址", trigger: "blur" }],
    },
    behaviorExportShow: false,
    query: {},
    exportDialog: false,
    exportData: [],
    topLoading: false,

    addBackstageVisible: false,
    addBackstageForm: {
      hotel_alias: "",
      template_id: "",
      hotel_contract: "",
    },
    backstageId: "",
    addLogTitle: "新建分组",
    addBackstageFormRules: {
      hotel_alias: [
        { required: true, message: "请输入分组名称", trigger: "blur" },
      ],
      template_id: [
        { required: true, message: "请选择分组模板", trigger: "blur" },
      ],
    },
    selectedHotel: {
      type: "",
      id: "",
      label: "",
      buildingProjectName: "",
    },
  }),
  methods: {
    // 获取模板 信息
    getSelectedHotel(val, type) {
      this.selectedHotel = val;
      this.addBackstageForm.template_id = val.id;
    },
    // 展开选择模板界面
    showTemplateDialog(val) {
      this.$refs.ehotelDialog.showDialog();
    },
    // 确认添加
    addBackstageTrue() {
      this.$refs["addBackstageForm"].validate((valid) => {
        if (valid) {
          if (this.backstageId) {
            this.$confirm("是否确认修改？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              let json = {
                id: this.backstageId,
                hotel_contract: this.addBackstageForm.hotel_contract,
                hotel_alias: this.addBackstageForm.hotel_alias,
              };
              editHotelSub(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("修改成功");
                    this.addBackstageVisible = false;
                    this.tableMeth();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {
                  this.$message.warning("修改失败");
                });
            });
          } else {
            this.$confirm("是否确认添加？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              addHotelGroup(this.addBackstageForm)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.$message.success("添加成功");
                    this.addBackstageVisible = false;
                    this.tableMeth();
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                })
                .catch((err) => {
                  this.$message.warning("添加失败");
                });
            });
          }
        } else {
          this.$message.warning("请将信息补充完整");
          return false;
        }
      });
      //  addQueue
    },
    //  取消添加
    addBackstageFasle() {
      this.addBackstageVisible = false;
      this.addBackstageForm = {
        hotel_alias: "",
        template_id: "",
      };
      this.selectedHotel = {
        type: "",
        id: "",
        label: "",
        buildingProjectName: "",
      };
      this.backstageId = "";
    },
    goLink() {
      window.open(process.env.VUE_APP_HOTEL_SERVER);
    },
    //所有酒店分文件下载
    exportHotel(url) {
      // window.open(this.GLOBAL.BASE_URL+url)
      window.open(url);
    },
    //获取导出列表
    getExportList() {
      this.$http
        .get(this.global.getExportAllHotelList, {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          if (res.data.code === 100000) {
            var exoprthotel = res.data.data;
            this.exportData = exoprthotel;
          }
        });
      this.exportDialog = true;
    },
    showUploadDialog(val, type) {
      this.query = val;
      const typeMsg = type;
      let currentUrl = this.$route.path;
      console.log(currentUrl);
      if (currentUrl == "/hotelManAll") {
        if (typeMsg == "info" || typeMsg == "dr" || typeMsg == "sys") {
          if (this.query.hotel_id) {
            let id = this.query.id;
            this.query.id = this.query.hotel_id;
            this.query.hotel_id = id;
            this.query.is_children = true;
          }
          this.topLoading = true;
          this.query.hotelState = this.pageInfo.hotelState;
          this.$http
            .get(this.global.view, {
              params: {
                token: this.token,
                id: this.query.id,
                hotel_project: this.query.hotelName,
              },
            })
            .then((res) => {
              if (res.data.code === 100000) {
                this.query.hotel_use_state = res.data.data.hotel_use_state;
                this.query.res = res;
                this.query.hotel_id = this.query.id;
                let dataItem = Object.assign(this.query);
                dataItem.is_newPage = true;
                sessionStorage.setItem("hoteliitem", JSON.stringify(dataItem));
                sessionStorage.setItem(
                  "firsthoteliitem",
                  JSON.stringify(dataItem)
                );
                const link = this.$router.resolve({
                  path: "/hotelAllContentInfo/",
                });
                window.open(link.href, "_blank");

                this.topLoading = false;
              }
            });
          // if(this.query.hotel_id){
          //   let  id = this.query.id;
          //    this.query.id= this.query.hotel_id;
          //    this.query.hotel_id= id;
          //    this.query.is_children = true;
          // }
          // this.$refs.remoteMaintenance.handleClose();
          // this.$refs.systemDrawer.handleClose();
          // this.topLoading = true;
          // this.$refs.hotelAllContent.contentShow = true;
          // this.$refs.hotelAllContent.type = "detail";
          // this.$refs.hotelAllContent.getHotalContentList(
          //   this.query.hotel_contract
          // );
          // this.query.hotelState = this.pageInfo.hotelState;
          // this.$http
          //   .get(this.global.view, {
          //     params: {
          //       token: this.token,
          //       id: this.query.id,
          //       hotel_project:this.query.hotelName
          //     },
          //   })
          //   .then((res) => {
          //     if (res.data.code === 100000) {
          //       this.query.hotel_use_state = res.data.data.hotel_use_state;
          //       this.$refs.hotelAllContent.getHotelDetail(res, this.query);
          //       this.topLoading = false;
          //     }
          //   });
        }
        // else if (typeMsg == "dr") {
        //     this.$refs.remoteMaintenance.open(this.query, this.funArr);
        //     this.$refs.hotelAllContent.handleClose();
        //     this.$refs.systemDrawer.handleClose();
        //   } else if (typeMsg == "sys") {
        //     console.log(this.query);
        //     this.$refs.systemDrawer.open(this.query, this.funArr);
        //     this.$refs.hotelAllContent.handleClose();
        //     this.$refs.remoteMaintenance.handleClose();
        // }
        else if (typeMsg == "add") {
          console.log(val);
          this.addBackstageForm.hotel_contract = val.hotel_contract;
          this.addBackstageVisible = true;
          this.addLogTitle = "新建分组";
        } else if (typeMsg == "edit") {
          console.log(val);
          this.backstageId = val.id;
          this.addBackstageForm.hotel_contract = val.hotel_contract;
          this.addBackstageForm.hotel_alias = val.hotel;
          this.addLogTitle = "修改名称";
          this.addBackstageVisible = true;
          // this.addBackstageForm.hotel_contract = val.hotel_contract;
          // this.addBackstageVisible = true;
        }
      } else {
        console.log(8484);
        if (typeMsg == "info") {
          if (this.query.hotel_id) {
            let id = this.query.id;
            this.query.id = this.query.hotel_id;
            this.query.hotel_id = id;
            this.query.is_children = true;
          }
          this.$refs.remoteMaintenance.handleClose();
          this.$refs.systemDrawer.handleClose();
          this.topLoading = true;
          this.$refs.hotelAllContent.contentShow = true;
          this.$refs.hotelAllContent.type = "detail";
          this.$refs.hotelAllContent.getHotalContentList(
            this.query.hotel_contract
          );
          this.query.hotelState = this.pageInfo.hotelState;
          this.$http
            .get(this.global.view, {
              params: {
                token: this.token,
                id: this.query.id,
                hotel_project: this.query.hotelName,
              },
            })
            .then((res) => {
              if (res.data.code === 100000) {
                this.query.hotel_use_state = res.data.data.hotel_use_state;
                this.$refs.hotelAllContent.getHotelDetail(res, this.query);
                this.topLoading = false;
              }
            });
        } else if (typeMsg == "dr") {
          this.$refs.remoteMaintenance.open(this.query, this.funArr);
          this.$refs.hotelAllContent.handleClose();
          this.$refs.systemDrawer.handleClose();
        } else if (typeMsg == "sys") {
          console.log(this.query);
          this.$refs.systemDrawer.open(this.query, this.funArr);
          this.$refs.hotelAllContent.handleClose();
          this.$refs.remoteMaintenance.handleClose();
        } else if (typeMsg == "add") {
          console.log(val);
          this.addBackstageForm.hotel_contract = val.hotel_contract;
          this.addBackstageVisible = true;
          this.addLogTitle = "新建分组";
        } else if (typeMsg == "edit") {
          console.log(val);
          this.backstageId = val.id;
          this.addBackstageForm.hotel_contract = val.hotel_contract;
          this.addBackstageForm.hotel_alias = val.hotel;
          this.addLogTitle = "修改名称";
          this.addBackstageVisible = true;
          // this.addBackstageForm.hotel_contract = val.hotel_contract;
          // this.addBackstageVisible = true;
        }
      }
    },
    SetConditionScreentype() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
        switch (this.accountType) {
          case 1:
            this.condition.screenType = "self";
            break;
          case 2:
            this.condition.screenType = "self";
            break;
          case 3:
            this.condition.screenType = "";
            break;
          case 4:
            this.condition.screenType = "";
            break;
          case 5:
            this.condition.screenType = "belong";
            break;
          case 6:
            this.condition.screenType = "belong";
        }
      });
    },
    showTableLoading() {
      this.table.loading = true;
    },
    tableMeth(orderVal, type) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      var params = {
        token: this.token,
        page: this.table.page,
        per_page: this.table.limit,
        hotel_brand: this.condition.hotel_brand,
        groupId:this.condition.groupId,
        hotel_store_id: this.condition.hotelStoreID,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        check_state: this.condition.acceptanceType,
        is_sub: this.condition.hasChildren,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,

        endTime: this.condition.endTime,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
        sort: sortStr,
        order: orderStr,
        screenType: this.condition.screenType,
        hotelName: this.condition.hotelName,
        hotelContract: this.condition.hotelContract,
        refresh: false,
      };
      if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
      } else if (params.belongPartnerCode !== "") {
        params.partner_id = params.belongPartnerCode;
      } else if (params.allotPartnerCode !== "") {
        params.partner_id = params.allotPartnerCode;
      }
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              this.condition.hotelCreateTimeStart = params.startTime;
              this.condition.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
          this.condition.hotelCreateTimeStart = this.today;
          this.condition.hotelCreateTimeEnd = this.today;
        }
      }
      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          if (type === "mounted") {
            this.table.page = sessionStorage.getItem("filterPage") * 1;
          }
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
            if (this.table.tableList.length > 0) {
              this.table.tableList = this.table.tableList.map((i) => {
                i.children = {};
                i.hasChildren = i.type_sub == "2" ? true : false;
                return i;
              });
            }

            this.$refs.tablePar.uploadChildren(
              this.addBackstageForm.hotel_contract
            );
            this.addBackstageFasle();
            this.backstageId = "";
            this.addBackstageForm.hotel_alias = "";
            this.addBackstageForm.hotel_contract = "";
          } else if (res.data.code === 100003) {
            this.$message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            this.$router.push("/login");
          } else {
            // this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
          this.table.loading = false;
        });
    },
    tableMethfrist(orderVal, type) {
      this.table.loading = true;
      var sortStr = "";
      var orderStr = "";
      if (orderVal) {
        if (orderVal.prop.indexOf("_text") !== -1) {
          var index = orderVal.prop.indexOf("_text");
          sortStr = orderVal.prop.substring(0, index);
        } else {
          sortStr = orderVal.prop;
        }
        orderVal.order === "ascending"
          ? (orderStr = "ASC")
          : (orderStr = "DESC");
      } else {
        sortStr = "id";
        orderStr = "DESC";
      }
      var params = {
        token: this.token,
        page: this.table.page,
        per_page: this.table.limit,
        hotel_brand: this.condition.hotel_brand,
         groupId:this.condition.groupId,
        hotel_store_id: this.condition.hotelStoreID,
        hotelBrand: this.condition.hotel_brand,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        check_state: this.condition.acceptanceType,
        is_sub: this.condition.hasChildren,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
        hotelName: this.condition.hotelName,
        hotelContract: this.condition.hotelContract,
        sort: sortStr,
        order: orderStr,
        screenType: "",
        refresh: true,
      };
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              this.condition.hotelCreateTimeStart = params.startTime;
              this.condition.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
          this.condition.hotelCreateTimeStart = this.today;
          this.condition.hotelCreateTimeEnd = this.today;
        }
      }

      this.$http
        .get(this.global.search, {
          params,
        })
        .then((res) => {
          if (type === "mounted") {
            this.table.page = sessionStorage.getItem("filterPage") * 1;
          }
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.table.tableList = tableData.items;
            this.table.total = parseInt(tableData.total);
            if (this.table.tableList.length > 0) {
              this.table.tableList = this.table.tableList.map((i) => {
                i.children = {};
                i.hasChildren = i.type_sub == "2" ? true : false;
                return i;
              });
            }
          } else if (res.data.code === 100003) {
            this.$message.error("登录状态以超时，请重新登录");
            sessionStorage.clear();
            localStorage.clear();
            this.$router.push("/login");
          } else {
            // this.$message.error(res.data.msg);
          }
          this.table.loading = false;
        })
        .catch((res) => {
          this.table.loading = false;
        });
    },
    getNewPage(newPage) {
      this.table.page = newPage;
      this.table.loading = true;
      this.tableMeth();
    },
    getNewLimit(newLimit) {
      this.table.limit = newLimit;
      this.table.loading = true;
      this.tableMeth();
    },
    newBuild() {
      this.$router.push("/hotelManNew");
    },
    next() {
      if (this.importData.active++ > 2) {
        this.dialogExport.dialog = false;
        this.importData.active = 0;
      }
      if (this.importData.active === 1) {
        this.previewMeth();
      } else if (this.importData.active === 2) {
        this.confirmMeth();
      } else if (this.importData.active === 3) {
        this.dialogExport.dialog = false;
      }
    },
    previewMeth() {
      var param = qs.stringify({
        token: this.token,
        file_path: this.importData.file,
      });
      this.$http
        .post(this.global.preview, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.importTable.tableList = tableData.items;
            this.importData.infoComplete = tableData.success_count;
            this.importData.infoIncomplete = tableData.error_count;
            this.importData.loading = false;
            this.cache_key = tableData.cache_key;
          } else {
            this.importData.loading = false;
            this.$message.error("预览失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    confirmMeth() {
      this.importData.loading = true;
      var param = qs.stringify({
        token: this.token,
        file_path: this.importData.file,
        cache_key: this.cache_key,
      });
      this.$http
        .post(this.global.importAction, param)
        .then((res) => {
          if (res.data.code === 100000) {
            var tableData = res.data.data;
            this.importData.successCount = tableData.success_count;
            this.importData.errorCount = tableData.error_count;
            this.importData.loading = false;
            this.importData.active = 3;
            // setTimeout(()=>{
            //     this.importData.active=3;
            // },300)
          } else {
            this.$message.error("导入失败");
          }
        })
        .catch(() => {
          this.$message.error("返回错误");
        });
    },
    getToday() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      this.today = year + "-" + month + "-" + day;
    },
    getTopSearchShow(type) {
      var auth = JSON.parse(sessionStorage.getItem("auth"));
      var auth_type = auth.type;
      this.auth_type = auth.type;
      if (
        auth_type === "channel" ||
        auth_type === "screen" ||
        auth_type === "factory"
      ) {
        this.topSearchShow = {
          hotel_group: true,
          province: true,
          city: true,
          country: true,
          keyword: true,
          coopType: true,
          coopPartnerType: true,
          hotelName: true,
          hotelContract: true,

          hasChildren: true,
        };
      } else if (auth_type === "group") {
        this.topSearchShow = {
          hotel_group: true,
          province: true,
          city: true,
          country: true,
          keyword: true,
          // coopPartnerType:true,
          hotelName: true,
          hotelContract: true,

          hasChildren: true,
        };
      } else if (auth_type === "htrip") {
        this.topSearchShow = {
          hotel_group: true,
          province: true,
          city: true,
          country: true,
          coopType: true,
          coopPartner: true,
          BD: true,
          keyword: true,
          coopPartnerType: true,
          hotelName: true,
          hotelContract: true,

          hasChildren: true,
        };
      }
      this.pageInfo.hotelState === "all"
        ? (this.topSearchShow.hotel_use_state = true)
        : "";
      // this.pageInfo.hotelState !== "newList"
      //   ? (this.topSearchShow.date = true)
      //   : "";
      // this.pageInfo.hotelState == "newList"
      //   ? (this.topSearchShow.date = true)
      //   : "";
      this.topSearchShow.keywordPlaceholder = "请输入酒店名称关键词";
      this.topSearchShow.hotelNamePlaceholder = "请输入终端门店程序名";
      this.topSearchShow.hotelContractPlaceholder = "请输入合同号";
      // this.topSearchShow.hotelStoreIDPlaceholder = "请输入集团门店ID";
    },
    boottime() {
      var params = {
        token: this.token,
        hotel_brand: this.condition.hotel_brand,
        hotel_store_id: this.condition.hotelStoreID,
        hotelBrand: this.condition.hotel_brand,
         groupId:this.condition.groupId,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        check_state: this.condition.acceptanceType,
        keyword: this.condition.keyword,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        isDeleted: this.condition.isDeleted,
      };
      return new Promise((resolve, reject) => {
        var param = qs.stringify({
          token: this.token,
        });
        this.$http.post(this.global.getIndexHeader, param).then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            this.getIndexHeader.partnerType = res.data.data.partnerType;
            this.getIndexHeader.accountId = res.data.data.accountId;
          } else {
          }
          if (
            this.getIndexHeader.partnerType !== "携旅" &&
            this.getIndexHeader.accountId == "HCC000262"
          ) {
            this.behaviorExportShow = true;
          } else {
            this.behaviorExportShow = false;
          }
        });
      });
    },
    getScreenTypeSearchShow() {
      switch (this.pageInfo.hotelState) {
        case "newList":
        case "del":
          this.showPart = {
            date: true,
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
        case "all":
          this.showPart = {
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
        case "using":
        case "operate":
          this.showPart = {
            date: false,
            self: true,
            other: true,
            videoRoom: true,
          };
          break;
      }
    },
    getCondition(val, filterPage, filterLimit, type) {
      
      switch (this.pageInfo.hotelState) {
        case "implementation":
          this.condition.hotel_use_state = "5";
          break;
        case "using":
          this.condition.hotel_use_state = "7"; //hotelManAllOperate
          break;
        case "offline":
          this.condition.hotel_use_state = "11";
          break;
        case "all":
          var hotel_use_state = val.hotel_use_state;
          if (Array.isArray(hotel_use_state)) {
            this.condition.hotel_use_state = val.hotel_use_state.join(",");
          } else if (hotel_use_state === "10") {
            this.condition.hotel_use_state = val.hotel_use_state;
          } else {
            this.condition.hotel_use_state = "";
          }
          break;
        case "del":
          this.condition.isDeleted = 1;
          break;
      }
      var hotel_use_state_label = this.condition.hotel_use_state_label;
      console.log(val);
      if(val.hotel_group&&val.hotel_group){
        let groupList =  val.hotel_group.map(i=>{
          return i[0]
        })
        let brandList =  val.hotel_group.map(i=>{
          return i[1]
        })
        this.condition.groupId = Array.from(new Set(groupList)).join(',')
         this.condition.hotel_brand = brandList.join(",")
      }
      console.log(this.condition);

      this.condition.hotel_use_state_label = Array.isArray(
        val.hotel_use_state_label
      )
        ? val.hotel_use_state_label.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label =
        "（" + this.condition.hotel_use_state_label.replace(/,/g, "，") + "）";
      if (this.condition.hotel_use_state_label === "（）") {
        this.condition.hotel_use_state_label = "（全部状态）";
      }
      this.condition.BD = Array.isArray(val.BD) ? val.BD.join(",") : "";
      this.condition.partner_id = Array.isArray(val.partner_id)
        ? val.partner_id.join(",")
        : ""; //ka
      this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode)
        ? val.belongPartnerCode.join(",")
        : ""; //ka
      this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode)
        ? val.allotPartnerCode.join(",")
        : ""; //ka
      if (typeof val.citycode === "string") {
        this.condition.citycode = val.citycode;
      } else {
        this.condition.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.condition.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //provinceLabel
      this.condition.cityLabel = val.cityLabel;
      this.condition.hotelStoreID = val.hotelStoreID;
      this.condition.countyLabel = val.countyLabel;
      this.condition.hasChildren = val.hasChildren;
      (this.condition.acceptanceType = val.acceptanceType),
        (this.condition.startTime = val.startTime);
      this.condition.endTime = val.endTime;
      this.condition.hotelName = val.hotelName;
      this.condition.hotelContract = val.hotelContract;

      if (this.pageInfo.hotelState === "newList") {
        this.condition.hotelCreateTimeStart =
          !val.hotelCreateTimeStart || val.hotelCreateTimeStart === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeStart;
        this.condition.hotelCreateTimeEnd =
          !val.hotelCreateTimeEnd || val.hotelCreateTimeEnd === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeEnd;
      }
      if (this.pageInfo.hotelState === "newList") {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.hotelCreateTimeStart,
          this.condition.hotelCreateTimeEnd
        );
      } else {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.startTime,
          this.condition.endTime
        );
      }
      this.condition.keyword = val.keyword;
      this.getFilterTableCon(filterPage, filterLimit, type);
      this.tableMeth("", type);
      this.getLineChart();
      this.showDataMeth();
    },
    getConditionfrist(val, filterPage, filterLimit, type) {
      switch (this.pageInfo.hotelState) {
        case "implementation":
          this.condition.hotel_use_state = "5";
          break;
        case "using":
          this.condition.hotel_use_state = "7"; //hotelManAllOperate
          break;
        case "offline":
          this.condition.hotel_use_state = "11";
          break;
        case "all":
          var hotel_use_state = val.hotel_use_state;
          if (Array.isArray(hotel_use_state)) {
            this.condition.hotel_use_state = val.hotel_use_state.join(",");
          } else if (hotel_use_state === "10") {
            this.condition.hotel_use_state = val.hotel_use_state;
          } else {
            this.condition.hotel_use_state = "";
          }
          break;
        case "del":
          this.condition.isDeleted = 1;
          break;
      }
      var hotel_use_state_label = this.condition.hotel_use_state_label;
     if(val.hotel_group&&val.hotel_group){
        let groupList =  val.hotel_group.map(i=>{
          return i[0]
        })
        let brandList =  val.hotel_group.map(i=>{
          return i[1]
        })
        this.condition.groupId = Array.from(new Set(groupList)).join(',')
         this.condition.hotel_brand = brandList.join(",")
      }
      this.condition.hotel_use_state_label = Array.isArray(
        val.hotel_use_state_label
      )
        ? val.hotel_use_state_label.join(",")
        : ""; //ka
      this.condition.hotel_use_state_label =
        "（" + this.condition.hotel_use_state_label.replace(/,/g, "，") + "）";
      if (this.condition.hotel_use_state_label === "（）") {
        this.condition.hotel_use_state_label = "（全部状态）";
      }
      this.condition.BD = Array.isArray(val.BD) ? val.BD.join(",") : "";
      this.condition.partner_id = Array.isArray(val.partner_id)
        ? val.partner_id.join(",")
        : ""; //ka
      this.condition.belongPartnerCode = Array.isArray(val.belongPartnerCode)
        ? val.belongPartnerCode.join(",")
        : ""; //ka
      this.condition.allotPartnerCode = Array.isArray(val.allotPartnerCode)
        ? val.allotPartnerCode.join(",")
        : ""; //ka
      if (typeof val.citycode === "string") {
        this.condition.citycode = val.citycode;
      } else {
        this.condition.citycode = Array.isArray(val.citycode)
          ? val.citycode.join(",")
          : ""; //citycode
      }
      this.condition.provinceLabel = Array.isArray(val.provinceLabel)
        ? val.provinceLabel.join(",")
        : ""; //provinceLabel
      this.condition.cityLabel = val.cityLabel;
      this.condition.countyLabel = val.countyLabel;
      this.condition.hasChildren = val.hasChildren;
      (this.condition.acceptanceType = val.acceptanceType),
        (this.condition.startTime = val.startTime);
      this.condition.endTime = val.endTime;
      if (this.pageInfo.hotelState === "newList") {
        this.condition.hotelCreateTimeStart =
          !val.hotelCreateTimeStart || val.hotelCreateTimeStart === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeStart;
        this.condition.hotelCreateTimeEnd =
          !val.hotelCreateTimeEnd || val.hotelCreateTimeEnd === ""
            ? this.curDate.curDay
            : val.hotelCreateTimeEnd;
      }
      if (this.pageInfo.hotelState === "newList") {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.hotelCreateTimeStart,
          this.condition.hotelCreateTimeEnd
        );
      } else {
        this.table.specialCell.darText = commonMeth.setTableDarText(
          this.condition.startTime,
          this.condition.endTime
        );
      }
      this.condition.keyword = val.keyword;
      this.getFilterTableCon(filterPage, filterLimit, type);
      this.tableMethfrist("", type);
      this.getLineChartfrist();
      this.showDataMethfrist();
    },
    screenTypeSearch(screenType, startTime, endTime) {
      this.condition.screenType = screenType;
      if (this.pageInfo.hotelState === "newList") {
        if (this.showPart.date) {
          if (startTime !== "") {
            this.condition.startTime = startTime;
            this.condition.endTime = endTime;
          }
        }
      } else {
        if (this.showPart.date) {
          this.condition.startTime = startTime;
          this.condition.endTime = endTime;
          this.condition.hotelCreateTimeStart = startTime;
          this.condition.hotelCreateTimeEnd = endTime;
        }
      }
      this.table.page = 1;
      this.table.per_page = 5;
      this.tableMeth();
      this.getLineChart();
      this.showDataMeth();
    },
    beforeUpload(val) {
      this.importData.file = val;
      this.importData.fileName = val.name;
    },
    uploadSuccess(response, file, fileList) {
      this.importData.file = response.data.url;
      this.importData.file = response.data.url;
      this.importData.active = 1;
      this.previewMeth();
    },
    cancelMeth() {
      this.importData.active = 0;
      this.importData.fileName = "";
      this.importData.infoComplete = "";
      this.importData.infoIncomplete = "";
      this.importData.readyCount = "";
      this.importData.successCount = "";
      this.importData.errorCount = "";
      this.importData.file = "";
      this.importData.filePath = "";
      this.dialogExport.dialog = false;
    },
    //数据统计
    showDataMeth() {
      return new Promise((resolve, reject) => {
        this.showLoading = true;
        var params = {
          token: this.token,
          hotel_brand: this.condition.hotel_brand,
          hotel_store_id: this.condition.hotelStoreID,
          hotelBrand: this.condition.hotel_brand,
          groupId:this.condition.groupId,
          citycode: this.condition.citycode,
          province: this.condition.provinceLabel,
          city: this.condition.cityLabel,
          county: this.condition.countyLabel,
          check_state: this.condition.acceptanceType,
          hotel_use_state: this.condition.hotel_use_state,
          accountManagerId: this.condition.BD,
          partner_id: this.condition.partner_id,
          keyword: this.condition.keyword,
          startTime: this.condition.startTime,
          endTime: this.condition.endTime,
          hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
          belongPartnerCode: this.condition.belongPartnerCode,
          allotPartnerCode: this.condition.allotPartnerCode,
          isDeleted: this.condition.isDeleted,
          screenType: this.condition.screenType,
          hotelName: this.condition.hotelName,
          hotelContract: this.condition.hotelContract,
          refresh: false,
        };
        if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
        } else if (params.belongPartnerCode !== "") {
          params.partner_id = params.belongPartnerCode;
        } else if (params.allotPartnerCode !== "") {
          params.partner_id = params.allotPartnerCode;
        }
        if (this.pageInfo.hotelState === "newList") {
          if (params.hotelCreateTimeStart != "") {
            //两个时间都有
            if (params.startTime !== "") {
              if (typeof params.startTime == "undefined") {
              } else {
                params.hotelCreateTimeStart = params.startTime;
                params.hotelCreateTimeEnd = params.endTime;
                params.startTime = "";
                params.endTime = "";
              }
            }
          } else {
            params.hotelCreateTimeStart = this.today;
            params.hotelCreateTimeEnd = this.today;
          }
        }
        this.$http
          .post(this.global.getAllHotelDataStatistics, qs.stringify(params))
          .then((res) => {
            var code = res.data.code;
            if (code === 100000) {
              this.showData = res.data.data;
              this.showLoading = true;
            }
            this.showLoading = false;
            resolve();
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    showDataMethfrist() {
      return new Promise((resolve, reject) => {
        this.showLoading = true;
        var params = {
          token: this.token,
          hotel_brand: this.condition.hotel_brand,
          hotel_store_id: this.condition.hotelStoreID,
          hotelBrand: this.condition.hotel_brand,
          groupId:this.condition.groupId,
          citycode: this.condition.citycode,
          province: this.condition.provinceLabel,
          city: this.condition.cityLabel,
          county: this.condition.countyLabel,
          check_state: this.condition.acceptanceType,
          hotel_use_state: this.condition.hotel_use_state,
          accountManagerId: this.condition.BD,
          partner_id: this.condition.partner_id,
          keyword: this.condition.keyword,
          startTime: this.condition.startTime,
          endTime: this.condition.endTime,
          hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
          hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
          belongPartnerCode: this.condition.belongPartnerCode,
          allotPartnerCode: this.condition.allotPartnerCode,
          isDeleted: this.condition.isDeleted,
          screenType: "",
          refresh: true,
          hotelName: this.condition.hotelName,
          hotelContract: this.condition.hotelContract,
          accountType: this.accountType,
        };
        if (this.pageInfo.hotelState === "newList") {
          if (params.hotelCreateTimeStart != "") {
            //两个时间都有
            if (params.startTime !== "") {
              if (typeof params.startTime == "undefined") {
              } else {
                params.hotelCreateTimeStart = params.startTime;
                params.hotelCreateTimeEnd = params.endTime;
                params.startTime = "";
                params.endTime = "";
              }
            }
          } else {
            params.hotelCreateTimeStart = this.today;
            params.hotelCreateTimeEnd = this.today;
          }
        }
        // params.screenType=this.condition.screenType
        this.$http
          .post(this.global.getAllHotelDataStatistics, qs.stringify(params))
          .then((res) => {
            var code = res.data.code;
            if (code === 100000) {
              this.showData = res.data.data;
              this.showLoading = true;
            }
            this.showLoading = false;
            resolve();
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    getFoldMeth(val) {
      this.fold = val;
    },
    getFilterTableCon(filterPage, filterLimit, type) {
      if (type === "mounted") {
        !filterPage || filterPage === ""
          ? (this.table.page = 1)
          : (this.table.page = filterPage);

        !filterLimit || filterLimit === ""
          ? (this.table.limit = 20)
          : (this.table.limit = filterLimit);
      } else {
        this.table.page = 1;
        this.table.limit = 20;
        sessionStorage.setItem("filterPage", "1");
        sessionStorage.setItem("filterLimit", "5");
      }
    },
    getFilterCon() {
      var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
      this.topSearchSave = filterCon;
      if (filterCon.screenType) {
        this.condition.screenType = filterCon.screenType.join(",");
      }
      var filterPage = sessionStorage.getItem("filterPage");
      var filterLimit = sessionStorage.getItem("filterLimit");
      this.getCondition(filterCon, filterPage * 1, filterLimit * 1, "mounted");
    },
    getFilterConfrist() {
      var filterCon = JSON.parse(sessionStorage.getItem("filterCon"));
      if (!filterCon) {
        filterCon = {};
      }
      this.topSearchSave = filterCon;
      if (filterCon.screenType) {
        this.condition.screenType = filterCon.screenType.join(",");
      }
      var filterPage = sessionStorage.getItem("filterPage");
      var filterLimit = sessionStorage.getItem("filterLimit");
      this.getConditionfrist(
        filterCon,
        filterPage * 1,
        filterLimit * 1,
        "mounted"
      );
    },
    getTableSelect(val) {
      var operateIds = "";
      var partnerCode = "";
      var hotelContract = "";
      val.forEach((item, index) => {
        operateIds += item.id + ",";
        partnerCode += item.code + ",";
        hotelContract += item.hotel_contract + ",";
      });
      this.operateIds = operateIds.slice(0, -1);
      this.hotelContract = hotelContract.slice(0, -1);
    },
    operateConfirmMeth(type, str) {
      if (this.operateIds !== "") {
        if (type === "editMac") {
          this.editMacDialog.visible = true;
        } else {
          this.$confirm("确定" + str + "？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.operateMeth(type, str);
            })
            .catch(() => {});
        }
      } else {
        this.$message({
          message: "请选择酒店",
          type: "warning",
        });
      }
    },
    delMeth() {
      var param = qs.stringify({
        token: this.token,
        id: this.delIds,
      });
      this.$http.post(this.global.hotelDestroy, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success("删除成功");
          this.tableMeth();
        } else {
          this.$message.success("删除失败");
        }
      });
    },
    operateMeth(type, str) {
      var param = "";
      var url = "";
      param = qs.stringify({
        token: this.token,
        id: this.operateIds,
        hotelContract: this.hotelContract,
      });
      if (type === "del") {
        url = this.global.hotelDestroy;
      } else if (type === "recover") {
        url = this.global.hotelRecover;
      } else if (type === "adv") {
        url = this.global.putOffLineAdvByHotel;
      }
      this.$http.post(url, param).then((res) => {
        if (res.data.code === 100000) {
          this.$message.success(`${str}成功`);
          this.tableMeth();
          this.getLineChart();
          this.showDataMeth();
        } else {
          this.$message.error(`${str}失败`);
        }
      });
    },
    editMacMath() {
      this.$refs["editMacForm"].validate((valid) => {
        if (valid) {
          var param = qs.stringify({
            token: this.token,
            hotelId: this.operateIds,
            mac: this.editMacDialog.mac,
            newMac: this.editMacDialog.newMac,
          });
          this.$http
            .post(this.global.editMacByHotelIdAndMac, param)
            .then((res) => {
              if (res.data.code === 100000) {
                this.$message.success(`修改成功`);
                this.editDialogCancel();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    editDialogCancel() {
      this.editMacDialog.visible = false;
      this.editMacDialog.mac = "";
      this.editMacDialog.newMac = "";
    },
    setDefaultQuickDateCur() {
      this.quickDateCur = "";
    },
    getLineChart() {
      this.lineChart.flag = false;
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.condition.hotel_brand,
        groupId:this.condition.groupId,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        check_state: this.condition.acceptanceType,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        keyword: this.condition.keyword,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        hotelName: this.condition.hotelName,
        hotelContract: this.condition.hotelContract,
        refresh: false,
      };
      if (params.belongPartnerCode == "" && params.allotPartnerCode == "") {
      } else if (params.belongPartnerCode !== "") {
        params.partner_id = params.belongPartnerCode;
      } else if (params.allotPartnerCode !== "") {
        params.partner_id = params.allotPartnerCode;
      }
      params.screenType = this.condition.screenType;
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
        }
      }
      this.$http
        .post(this.global.getHotelLineLegend, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend;
              this.lineChart.option.xData = chartData.xAxis;
              var dataTransEd = commonMeth.dataTrans(chartData);
              var arr = [];
              var index = -1;
              for (let key in dataTransEd.series) {
                index++;
                var obj = {
                  name: `${key}`,
                  type: "line",
                  smooth: true,
                  symbolSize: this.global.symbolSize, //拐点圆的大小
                  itemStyle: {
                    normal: {
                      color: GLOBAL.chartColor[index], //改变折线点的颜色
                      lineStyle: {
                        color: GLOBAL.chartColor[index], //改变折线颜色
                      },
                    },
                  },
                  data: dataTransEd.series[key],
                };
                arr.push(obj);
              }
              this.lineChart.option.series = arr;
            } else {
              this.lineChart.noData = true;
            }
            this.lineChart.flag = true;
            this.lineLoading = false;
          } else if (code === 100001) {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },

    getLineChartfrist() {
      this.lineChart.flag = false;
      this.lineLoading = true;
      var params = {
        token: this.token,
        hotelBrand: this.condition.hotel_brand,
        groupId:this.condition.groupId,
        citycode: this.condition.citycode,
        province: this.condition.provinceLabel,
        city: this.condition.cityLabel,
        county: this.condition.countyLabel,
        hotel_use_state: this.condition.hotel_use_state,
        accountManagerId: this.condition.BD,
        partner_id: this.condition.partner_id,
        startTime: this.condition.startTime,
        check_state: this.condition.acceptanceType,
        endTime: this.condition.endTime,
        keyword: this.condition.keyword,
        hotelCreateTimeStart: this.condition.hotelCreateTimeStart,
        hotelCreateTimeEnd: this.condition.hotelCreateTimeEnd,
        belongPartnerCode: this.condition.belongPartnerCode,
        allotPartnerCode: this.condition.allotPartnerCode,
        refresh: true,
        accountType: this.accountType,
        hotelName: this.condition.hotelName,
        hotelContract: this.condition.hotelContract,
      };
      if (this.pageInfo.hotelState === "newList") {
        if (params.hotelCreateTimeStart != "") {
          //两个时间都有
          if (params.startTime !== "") {
            if (typeof params.startTime == "undefined") {
            } else {
              params.hotelCreateTimeStart = params.startTime;
              params.hotelCreateTimeEnd = params.endTime;
              params.startTime = "";
              params.endTime = "";
            }
          }
        } else {
          params.hotelCreateTimeStart = this.today;
          params.hotelCreateTimeEnd = this.today;
        }
      }
      params.screenType = "";
      this.$http
        .post(this.global.getHotelLineLegend, qs.stringify(params))
        .then((res) => {
          var code = res.data.code;
          if (code === 100000) {
            var chartData = res.data.data;
            if (chartData.series) {
              this.lineChart.option.legend = chartData.legend;
              this.lineChart.option.xData = chartData.xAxis;
              var dataTransEd = commonMeth.dataTrans(chartData);
              var arr = [];
              var index = -1;
              for (let key in dataTransEd.series) {
                index++;
                var obj = {
                  name: `${key}`,
                  type: "line",
                  smooth: true,
                  symbolSize: this.global.symbolSize, //拐点圆的大小
                  itemStyle: {
                    normal: {
                      color: GLOBAL.chartColor[index], //改变折线点的颜色
                      lineStyle: {
                        color: GLOBAL.chartColor[index], //改变折线颜色
                      },
                    },
                  },
                  data: dataTransEd.series[key],
                };
                arr.push(obj);
              }
              this.lineChart.option.series = arr;
            } else {
              this.lineChart.noData = true;
            }
            this.lineChart.flag = true;
            this.lineLoading = false;
          } else if (code === 100001) {
            this.$message.error("折线图获取失败");
          }
          this.lineLoading = false;
        });
    },
    getConditionScreenType() {
      var param = qs.stringify({
        token: this.token,
      });
      this.$http.post(this.global.getIndexHeader, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.accountType = res.data.data.accountType;
        } else {
        }
      });
    },
    getTableSpecialCell() {
      switch (this.pageInfo.hotelState) {
        case "newList":
        case "implementation":
        case "using":
        case "offline":
        case "all":
          this.table.specialCell = {
            dar: true,
          };
          break;
      }
    },
    editCodeConfirmMeth() {
      this.$refs["editCodeForm"].validate((valid) => {
        if (valid) {
          this.$confirm("确定修改?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.editCodePost();
          });
        } else {
          this.$message.warning("请完善信息");
        }
      });
    },
    editCodePost() {
      var param = qs.stringify({
        token: this.token,
        event: this.editCodeForm.event,
        mobile: this.editCodeForm.mobile,
        code: this.editCodeForm.code,
      });
      this.$http.post(this.global.resetSmsCode, param).then((res) => {
        var code = res.data.code;
        if (code === 100000) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    this.path = this.$route.path;
    if (this.path.indexOf("onePage") != -1) {
      this.fold.showSearch = true;
    }

    commonMeth.getTreeData(this.table.option).then((res) => {
      console.log(this.funArr);
      this.funArr = res.funArr;
      sessionStorage.setItem("funArr", JSON.stringify(this.funArr));
      this.table.option = res.flag;
      this.table.specialCell.patternBtn = this.showFunModel("模式修改");
      this.table.specialCell.instructionBtn = this.showFunModel("远程维护");
      this.table.specialCell.systemBtn = this.showFunModel("系统管理");
      //  this.table.specialCell.addBackstage = this.showFunModel("新建跟组");
      this.table.specialCell.addBackstage = this.showFunModel("新建分组");
      this.table.specialCell.editName = this.showFunModel("修改名称");
    });
    this.$store.commit("getCurDay");
    this.curDate = this.$store.state.curDate;
    this.getTableSpecialCell();
    this.token = sessionStorage.getItem("token");
    this.curCompanyName = sessionStorage.getItem("curCompanyName");
    this.SetConditionScreentype();
    this.getConditionScreenType();
    this.getToday();
    this.headers.token = this.token;
    this.headers.Authorization = this.token;
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
    this.condition.hotel_use_state_label = "（全部状态）";
    this.getTopSearchShow();
    this.getScreenTypeSearchShow();
    // this.getFilterCon();
    this.getFilterConfrist();
    this.table.otherFromUrl = this.pageInfo.fromUrl;
    this.boottime();
  },
  computed: {
    showDataCom() {
      return function (val) {
        if (val === null || val === "") {
          return 0;
        } else {
          return parseFloat(val).toLocaleString();
        }
      };
    },
    showFunModel() {
      return function (val) {
        if (this.funArr.indexOf(val) !== -1) {
          return true;
        } else {
          return false;
        }
      };
    },
    twoPointCom() {
      return function (x) {
        !x ? (x = 0) : "";
        var f = parseFloat(x);
        if (isNaN(f)) {
          return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s;
      };
    },
    spanCom() {
      var str = 1;
      this.pageInfo.hotelState !== "del" ? (str = 6) : (str = 8);
      return str;
    },
    showDataTime() {
      return function (type) {
        var startTime = "";
        var endTime = "";
        if (this.pageInfo.hotelState === "newList") {
          startTime = this.condition.startTime;
          endTime = this.condition.endTime;
        } else {
          startTime = this.condition.startTime;
          endTime = this.condition.endTime;
        }
        if (
          !startTime ||
          startTime === "" ||
          (startTime === endTime &&
            startTime === this.$store.state.curDate.curDay)
        ) {
          return "【即时】";
        }
        if (type == "end") {
          var time = "【" + endTime + "】";
          return time;
        } else {
          var time = "【" + startTime + "~" + endTime + "】";
          return time;
        }
      };
    },
    showRateText() {
      if (this.pageInfo.hotelState === "newList") {
        if (
          this.condition.hotelCreateTimeStart === "" ||
          this.condition.hotelCreateTimeStart ===
            this.condition.hotelCreateTimeEnd
        ) {
          return "日活率";
        } else {
          return "平均日活率";
        }
      } else {
        if (
          this.condition.startTime === "" ||
          this.condition.startTime === this.condition.endTime
        ) {
          return "日活率";
        } else {
          return "平均日活率";
        }
      }
    },
    allLoadingStateCom() {
      var hotelState = this.pageInfo.hotelState;
      if (hotelState === "newList" || hotelState === "del") {
        this.allLoadingState = commonMeth.getAllLoading(
          this.table.loading,
          false,
          this.showLoading
        );
      } else {
        this.allLoadingState = commonMeth.getAllLoading(
          this.table.loading,
          this.lineLoading,
          this.showLoading
        );
      }
    },
  },
  watch: {
    pageInfo: {
      handler: function (newVal) {
        this.table.otherFromUrl = newVal.fromUrl;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.bread {
  flex: 1;
}
.hotelBrand {
  width: 100%;
}
.el-table {
  margin-top: 15px;
}
.pageBox {
  margin-top: 15px;
}
.container {
  /*height:400px;*/
  /*background:red;*/
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}
.stepItem {
  width: 100%;
}
.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}
.imgBox {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  img {
    height: 100%;
    width: 100%;
  }
}
.none {
  display: none;
}
.notice {
  width: 100%;
  background: #eef2f7;
  padding: 20px 20px;
  margin-top: 30px;
  box-sizing: border-box;
  div {
    color: #909ca7;
  }
  .title {
    font-size: 16px;
    padding-bottom: 20px;
  }
  .item {
    margin-bottom: 20px;
  }
  .item:last-child {
    margin-bottom: 0;
  }
  .downLoad > a {
    color: #418efa;
    margin-left: 20px;
    cursor: pointer;
  }
}
.two {
  .showCon {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    border: 1px dashed #ddd;
    overflow: auto;
  }
  .bottom {
    .show {
      display: flex;
    }
    .infoIncomplete {
      margin-left: 20px;
    }
    div {
      margin-bottom: 15px;
    }
  }
}
.three {
  width: 100%;
  height: 300px;
  .dataShow {
    display: flex;
    justify-content: center;
  }
  .error {
    color: #dd2139;
  }
}
.four {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  .notice {
    width: 100%;
    text-align: center;
    background: none;
  }
  .iconBox {
    text-align: center;
    font-size: 80px;
    color: #20b759;
    margin-top: 30px;
  }
}
.paddingBox {
  padding: 15px 0;
}
.foldBtn {
  cursor: pointer;
}
.outTopBox {
  /*padding:0;*/
}
.btnRow {
  display: flex;
  margin-top: 20px;
}
.rightBtn {
  margin-left: auto;
}
.quickDateBox {
  display: flex;
  align-items: center;
}
.quickDateSearch {
  display: flex;
}
.quickDateSearch .item {
  border: 1px solid #dcdfe6;
  color: #606266;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  padding: 12px 20px;
  cursor: pointer;
  margin-right: 15px;
}
.quickDateSearch .item:last-child {
  margin-right: 0;
}
.quickDateSearch .item.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>
