import { render, staticRenderFns } from "./IndexDoMore.vue?vue&type=template&id=f14125ba&scoped=true&"
import script from "./IndexDoMore.vue?vue&type=script&lang=js&"
export * from "./IndexDoMore.vue?vue&type=script&lang=js&"
import style0 from "./IndexDoMore.vue?vue&type=style&index=0&id=f14125ba&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f14125ba",
  null
  
)

export default component.exports