<template>
  <div style="padding:0 15px">
    <div class="btnRow" v-if="first!=='no'">
      <!-- <el-button type="primary" class="largeBtn" @click="openAddDialog('star','addStar')">添加根目录</el-button> -->
    </div>
    <!-- 手风琴 面板 -->
    <el-collapse accordion v-if="content.length>0">
      <el-collapse-item class="firstBorder" v-model="activeName" v-for="item in content" :key="item.id" :name="item.id">
        <template slot="title">
          <span class="titleText">{{item.mname}}</span>
          <span class="titleText">{{item.attrName}}</span>
          <span class="titleText">{{item.morder}}</span>
        </template>
        <div class="collapseContent">
          <div class="collapseFirstBtnRow">
            <el-button type="text" @click="openAddDialog('nostar','addChidren',item.id,item)">添加子目录</el-button>
            <el-button type="text" v-if="first=='no'" @click="openAddDialog('nostar','edit',item.id)">修改</el-button>
            <el-button v-if="item.attr == 3 || item.attr == 28 || item.attr == 29 || item.attr == 30 || item.attr == 34 || item.attr == 42 || item.attr == 43 || item.attr == 46 || item.attr == 49 || item.attr == 53|| item.attr == 117" type="text" @click="openContentDialog('nostar',item)">详情</el-button>
            <el-button v-if="first=='no'" type="text" style="color:red" @click="delNode(item.id)">删除</el-button>
          </div>
          <template v-if="item.child">
            <!-- 自调用 -->
            <launcher @updata="updataLauncher" :jumpOptionsTwo='jumpOptions' :jumpOptionsFour='jumpOptions3' :menuList='menuAllList' :attrList="attrListTwo" :adsListTwo="adsList" :jumpListTwo="jumpList" :content="item.child" :query="query" first="no" />
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
    <!-- 所有表单 -->
    <el-dialog title="目录设置" :visible.sync="dialogVisible" width="750px" :before-close="handleClose" :append-to-body="true">
      <el-form :rules="rules" ref="setForm" :model="setForm" label-width="120px">
        <el-form-item label="中文名称" prop='name1'>
          <el-input v-model="setForm.name1"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name1==''" type="primary" class="largeBtn marginLeft" @click="openUPload('1')">中文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="中文文字" prop='wordCn'>
          <el-input type="textarea" v-model="setForm.wordCn" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <el-form-item label="英文名称" prop='name2' v-if="setForm.attr!=88&&setForm.attr!=78&&setForm.attr!=71&&setForm.attr!=79">
          <el-input v-model="setForm.name2"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name2==''" type="primary" class="largeBtn marginLeft" @click="openUPload('2')">英文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="英文文字" prop='wordEn'>
          <el-input type="textarea" v-model="setForm.wordEn" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <el-form-item label="日文名称" prop='name3' v-if="setForm.attr!=88&&setForm.attr!=78&&setForm.attr!=71&&setForm.attr!=79">
          <el-input v-model="setForm.name3"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name3==''" type="primary" class="largeBtn marginLeft" @click="openUPload('3')">日文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="日文文字" prop='wordJp'>
          <el-input type="textarea" v-model="setForm.wordJp" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <el-form-item label="韩文名称" prop='name4' v-if="setForm.attr!=88&&setForm.attr!=78&&setForm.attr!=71&&setForm.attr!=79">
          <el-input v-model="setForm.name4"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name4==''" type="primary" class="largeBtn marginLeft" @click="openUPload('4')">韩文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="韩文文字" prop='wordKr'>
          <el-input type="textarea" v-model="setForm.wordKr" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <!-- 新增文言 -->
        <el-form-item label="法文名称" prop='name5' v-if="setForm.attr!=88&&setForm.attr!=78&&setForm.attr!=71&&setForm.attr!=79">
          <el-input v-model="setForm.name5"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name5==''" type="primary" class="largeBtn marginLeft" @click="openUPload('5')">法文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="法文文字" prop='wordKr'>
          <el-input type="textarea" v-model="setForm.wordFr" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <el-form-item label="西班牙文名称" prop='name6' v-if="setForm.attr!=88&&setForm.attr!=78&&setForm.attr!=71&&setForm.attr!=79">
          <el-input v-model="setForm.name6"></el-input>
          <el-button v-if="setForm.attr==3||setForm.attr==34||setForm.attr==42||setForm.attr==53|| setForm.attr == 117" :disabled="setForm.name6==''" type="primary" class=" marginLeft" @click="openUPload('6')">西班牙文图片</el-button>
        </el-form-item>
        <el-form-item v-if="setForm.attr==33||setForm.attr==53" label="西班牙文文字" prop='wordKr'>
          <el-input type="textarea" v-model="setForm.wordSp" resize="none" style="height:60px"></el-input>
        </el-form-item>
        <!-- 新增文言 -->
        <el-form-item label="属性" prop='attr' v-if="!menuTure">
          <el-select v-model="setForm.attr" placeholder="属性" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="changeSelect">
            <el-option v-for="(value,key,index) in attrListTwo" :key="index+'attr'" :label="value" :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="属性" prop='attr' v-if="menuTure">
          <el-select v-model="setForm.attr" placeholder="属性" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option v-for="(value,key,index) in attrListOne" :key="index+'attr'" :label="value" :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <uploadPicText :titleDataList="titleDataList" :sourceType="setForm.attr==88?'pic':'video'" v-if="setForm.attr==88||setForm.attr==78" @defaulttitledata="gettitleData" style="margin-bottom:20px"></uploadPicText>
        <uploadPicTextMore :titleDataList="titleDataList" :sourceType="setForm.attr==79?'pic':'video'" v-if="setForm.attr==71||setForm.attr==79" @defaulttitledata="gettitleData2" :is_first='true' style="margin-bottom:20px"></uploadPicTextMore>
        <el-form-item label="焦点图" v-if="setForm.parentname=='功能模块'">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.focus.picadd" @sendmd5="getfocusMd5"></uploadOnePic>
        </el-form-item>
        <el-form-item label="默认图" v-if="setForm.parentname=='功能模块'">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.default.picadd" @sendmd5="getdefaultMd5"></uploadOnePic>
        </el-form-item>
        <el-form-item label="视频" prop='videoRes' v-if="setForm.attr==37">
          <uploadOneMedia acceptStr="video/mp4,video/MP4" text="上传视频" v-model="setForm.videoRes"></uploadOneMedia>
        </el-form-item>
        <el-form-item label="音频" prop='musicRes' v-if="setForm.attr==41">
          <uploadOneMedia acceptStr="audio/mp3,audio/MP3" text="上传音频" v-model="setForm.musicRes"></uploadOneMedia>
        </el-form-item>
        <el-form-item label="横版图" prop='picHorizontal' v-if="setForm.attr==46">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picHorizontal"></uploadOnePic>
        </el-form-item>
        <el-form-item label="竖版图" prop='picVertical' v-if="setForm.attr==46">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picVertical"></uploadOnePic>
        </el-form-item>
        <el-form-item label="焦点图标" prop='picHorizontal' v-if="setForm.attr==49">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picHorizontal"></uploadOnePic>
        </el-form-item>
        <el-form-item label="默认图标" prop='picVertical' v-if="setForm.attr==49">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picVertical"></uploadOnePic>
        </el-form-item>
        <el-form-item label="模块图片" prop='picHorizontal' v-if="setForm.attr=='43'">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picHorizontal"></uploadOnePic>
        </el-form-item>
        <el-form-item label="封面图" prop='picHorizontal' v-if="setForm.attr==41">
          <uploadOnePic acceptStr="image/jpg,image/JPG,image/jpeg,image/JPEG,image/png,image/PNG" text="上传图片" v-model="setForm.picHorizontal"></uploadOnePic>
        </el-form-item>
        <el-form-item label="上级名称" prop='parentname' v-if="first=='no'">
          <el-select :disabled="setForm.id==''" v-model="setForm.parentname" placeholder="上级名称" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option v-for="(value) in menuAllList2" :key="value+'parentname'" :label="value" :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级名称" prop='parentname' v-if="first!='no'">
          <el-select :disabled="setForm.id==''" v-model="setForm.parentname" placeholder="上级名称" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option v-for="(value) in menuAllList" :key="value+'parentname'" :label="value" :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="上级名称" prop='parentname'>
          <el-input v-model="setForm.parentname"></el-input>
        </el-form-item> -->
        <el-form-item label="排序号" prop='morder'>
          <el-input v-model="setForm.morder"></el-input>
        </el-form-item>
        <el-form-item label="level" prop='level'>
          <el-input v-model="setForm.level" ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop='bljlm' v-if="setForm.attr!='39'&&setForm.attr!='48'&&setForm.attr!='43'&&setForm.bltype!=4&&setForm.bltype!=5">
          <el-input v-model="setForm.bljlm" :disabled="setForm.bltype==6||setForm.bltype==0||setForm.bltype==''"></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop='bljlm' v-if="setForm.attr!='39'&&setForm.attr!='48'&&setForm.attr!='43'&&setForm.bltype==4&&first=='no'">
          <el-cascader style="width:100%" v-model="setForm.bljlm" :options="jumpOptions2" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="链接地址" prop='bljlm' v-if="setForm.attr!='39'&&setForm.attr!='48'&&setForm.attr!='43'&&setForm.bltype==4&&first!='no'">
          <el-cascader style="width:100%" v-model="setForm.bljlm" :options="jumpOptions" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="链接地址" prop='bljlm' v-if="setForm.attr!='39'&&setForm.attr!='48'&&setForm.attr!='43'&&setForm.bltype==5&&first=='no'">
          <el-cascader style="width:100%" v-model="setForm.bljlm" :options="jumpOptions4" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="链接地址" prop='bljlm' v-if="setForm.attr!='39'&&setForm.attr!='48'&&setForm.attr!='43'&&setForm.bltype==5&&first!='no'">
          <el-cascader style="width:100%" v-model="setForm.bljlm" :options="jumpOptions3" :show-all-levels="false"></el-cascader>
        </el-form-item>
        <el-form-item label="跳转地址" prop='bljlm' v-if="setForm.attr=='43'">
          <el-select v-model="setForm.bljlm" placeholder="请选择跳转地址" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option v-for="(item,index) in jumpList" :key="index+'ads'" :label="item.mname" :value="item.mname">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告刊位" prop='bljlm' v-if="setForm.attr=='39'||setForm.attr=='48'">
          <el-select v-model="setForm.bljlm" placeholder="请选择链接类型" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option label="酒店独立运营" value="onlyHotel"></el-option>
            <el-option v-for="(item,index) in adsList" :key="index+'ads'" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接类型" prop='bltype'>
          <el-select v-model="setForm.bltype" placeholder="请选择链接类型" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="linkTypeChange">
            <el-option v-for="(item,index) in linkTypeList" :key="index+'type'" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="拥有者" prop='owner'>
          <el-select v-model="setForm.owner" placeholder="请选择拥有者" class="hotelBrand fullSelect " filterable collapse-tags clearable>
            <el-option v-for="(item,index) in ownerList" :key="index+'owner'" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitData('setForm')" :loading="submiting">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 所有表单 -->
    <!-- 部分节点资源详情 -->
    <el-dialog title="配置详情" :visible.sync="contentVisible" width="60%" :before-close="contentClose" :append-to-body="true">
      <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    </el-dialog>
    <!-- 部分节点资源详情 -->
    <!-- 批量选择图片 -->
    <uploadMorePic ref="uploadMorePic" @defaultData="getPicList" />
    <!-- 批量选择图片 -->

  </div>
</template>
<script>
import uploadPicText from "../common/uploadPicText.vue";
import uploadMorePic from "../common/uploadMorePic.vue";
import uploadOnePic from "../common/uploadOnePic.vue";
import uploadOneMedia from "../common/uploadOneMedia.vue";
import uploadPicTextMore from "../common/uploadPicTextMore.vue";

import {
  getMenuAttrList,
  getEditMenuJson,
  showMenuContentDetail,
  delAllMenu,
  addMenu,
  getAdsModule,
  getMenuModule,
  getLauncherName,
  getFunctionName,
} from "../../api/launcher";
export default {
  name: "launcher",
  components: {
    uploadMorePic,
    uploadOnePic,
    uploadOneMedia,
    uploadPicText,
    uploadPicTextMore,
  },
  props: {
    // 数据
    content: {
      type: Array,
      default: () => [],
    },
    // 是否为根界面
    first: {
      type: String,
      default: "",
    },
    // 酒店信息
    query: {
      type: Object,
      default: () => {},
    },
    // 属性列表
    attrList: {
      type: Object,
      default: () => {},
    },
    // 广告列表
    adsListTwo: {
      type: Array,
      default: () => [],
    },
    menuList: {
      type: Array,
      default: () => [],
    },
    // 跳转列表
    jumpListTwo: {
      type: Array,
      default: () => [],
    },
    jumpOptionsTwo: {
      type: Array,
      default: () => [],
    },
    jumpOptionsFour: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      jumpOptions: [],
      jumpOptions2: [],
      jumpOptions3: [],
      jumpOptions4: [],
      // 表格数据信息
      tableData: {
        tableList: [{}],
        checked: false,
        noPagination: true,
        tableLoading: false,
        page: 1,
        limit: 10,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "60",
            type: "text",
          },
          {
            label: "标题",
            prop: "content",
            minWidth: "80",
            type: "text",
          },
          {
            label: "语言",
            prop: "language",
            minWidth: "80",
            type: "text",
          },
          {
            label: "缩略图",
            prop: "picadd",
            minWidth: "80",
            type: "pic",
          },
          {
            label: "操作",
            minWidth: "80",
            type: "button",
            prop: "num",
            del: (item) => {
              this.$confirm("是否确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  let json = {
                    id: this.setForm.id,
                    attr: this.editAttr,
                    opertion: "del",
                    cid: item.id,
                    database: this.query.hotelName,
                  };
                  showMenuContentDetail(json)
                    .then((res) => {
                      if (res.data.code == 100000) {
                        this.$message.success("删除成功");
                        this.getTableData();
                      } else {
                        this.$message.warning(res.data.msg);
                      }
                    })
                    .catch((err) => {
                      this.$message.warning("删除失败");
                    });
                })
                .catch(() => {});
            },
          },
        ],
      },
      activeList: [],
      // 链接类型表
      linkTypeList: [
        {
          value: "0",
          label: "默认",
        },
        {
          value: "1",
          label: "包名",
        },
        {
          value: "2",
          label: "网页",
        },
        {
          value: "3",
          label: "视频",
        },
        {
          value: "4",
          label: "跳转模块",
        },
        {
          value: "5",
          label: "跳转详情",
        },
        {
          value: "6",
          label: "跳转列表",
        },
      ],
      // 广告刊位表
      adsList: [
        {
          id: "1",
          name: "开机视频广告",
          flag: "startVideo",
          type: "1",
          state: "1",
          updatetime: "1523944940",
        },
        {
          id: "2",
          name: "开机静态页面广告",
          flag: "startPic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "3",
          name: "电视直播主界面右侧广告",
          flag: "tvPic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "4",
          name: "高清影视主界面右侧广告",
          flag: "moviePic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "5",
          name: "酒店服务主界面右侧广告",
          flag: "servicePic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "6",
          name: "特色美食主界面右侧广告",
          flag: "foodPic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "7",
          name: "电视直播主界面左侧广告",
          flag: "tvVideo",
          type: "1",
          state: "1",
          updatetime: "0",
        },
        {
          id: "8",
          name: "高清影视主界面左侧广告",
          flag: "movieVideo",
          type: "1",
          state: "1",
          updatetime: "0",
        },
        {
          id: "9",
          name: "酒店服务主界面左侧广告",
          flag: "serviceVideo",
          type: "1",
          state: "1",
          updatetime: "0",
        },
        {
          id: "10",
          name: "特色美食主界面左侧广告",
          flag: "foodVideo",
          type: "1",
          state: "1",
          updatetime: "0",
        },
        {
          id: "11",
          name: "高清影视切屏广告",
          flag: "movieEnterPic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "12",
          name: "高清影视前贴片广告",
          flag: "movieEnterVideo",
          type: "1",
          state: "1",
          updatetime: "0",
        },
        {
          id: "13",
          name: "高清影视暂停广告",
          flag: "moviePausePic",
          type: "2",
          state: "1",
          updatetime: "0",
        },
        {
          id: "14",
          name: "企业专享频道",
          flag: "moviePic4",
          type: "3",
          state: "1",
          updatetime: "0",
        },
        {
          id: "15",
          name: "电视直播切屏广告",
          flag: "tvEnterPic",
          type: "2",
          state: "1",
          updatetime: "1",
        },
      ],
      // jump
      jumpList: [],
      // 用户表
      ownerList: [
        {
          value: "0",
          label: "所有",
        },
        {
          value: "1",
          label: "酒店",
        },
        {
          value: "2",
          label: "运营",
        },
        {
          value: "3",
          label: "广告",
        },
      ],
      menuTure: true,
      // 根目录添加属性
      attrListOne: {
        1: "目录",
        99: "自定义",
      },
      // 节点添加属性
      attrListTwo: {},
      // 表单
      setForm: {
        name1: "", // 中文名称
        name2: "", // 英文名称
        name3: "", // 日文名称
        name4: "", // 韩文名称
        name5: "", // 法文名称
        name6: "", // 西班牙文名称
        wordCn: "", //	中文文字
        wordEn: "", //	英文文字
        wordJp: "", //	日文文字
        wordKr: "", //	韩文文字
        wordFr: "", //	法文文字
        wordSp: "", //	西班牙文文字
        attr: "", // 属性
        parentname: "", //	上级名称
        morder: "", //	排序号：
        menu_pic: "", //	备填项
        bljlm: "", //链接地址：	跳转模块地址	广告刊位
        bltype: "0", //	链接类型
        level: "", //level
        id: "", //	？
        owner: "", //	拥有者
        picList_1: "", //中文图片
        picList_2: "", //英文图片
        picList_3: "", //日文图片
        picList_4: "", //韩文图片
        picList_5: "", //法文图片
        picList_6: "", //西班牙文图片
        picHorizontal: "", // 横板图	焦点图片	跳转模块图片
        picVertical: "", // 竖版图	默认图片
        videoRes: "", //视频资源
        musicRes: "", //音频资源
        titleList: "",
        focus: {
          id: "",
          md5: "",
          picadd: "",
        },
        default: {
          id: "",
          md5: "",
          picadd: "",
        },
      },
      // 部分验证
      rules: {
        name1: [{ required: true, message: "请输入中文名称", trigger: "blur" }], // 中文名称
        attr: [{ required: true, message: "请选择目录属性", trigger: "blur" }], // 属性

        //  morder: [{ required: true, message: "请填写排序", trigger: "blur" }], //	排序号：
        bltype: [{ required: true, message: "选择链接类型", trigger: "blur" }], //	链接类型
        //level: [{ required: true, message: "请填写level", trigger: "blur" }], level
        // owner: [{ required: true, message: "选择拥有者", trigger: "blur" }], 	拥有者
      },
      dialogVisible: false,
      contentVisible: false,
      checkList: [],
      editAttr: "",
      submiting: false,
      // 描述图文特定字段
      titleDataList: [],
      noPicList: [],
      noTitleList: [],
      noSortList: [],
      noPicList2: [],
      menuAllList: [],
      menuAllList2: [],
    };
  },
  mounted() {
    // 根目录加载属性列表
    if (this.first != "no") {
      this.getAttrList();
      this.getMenuAllList();
    }
  },
  methods: {
    getfocusMd5(md5) {
      this.setForm.focus.md5 = md5;
    },
    getdefaultMd5(md5) {
      this.setForm.default.md5 = md5;
    },
    // 刷新数据
    updataLauncher() {
      this.$emit("updata");
    },
    getMenuAllList() {
      getLauncherName({ database: this.query.hotelName }).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data) {
            this.menuAllList = res.data.data;
          } else {
            this.menuAllList = [];
          }
        }
      });
    },
    // 删除节点
    delNode(id) {
      this.$confirm("是否确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let json = {
            database: this.query.hotelName,
            arr: id,
          };
          delAllMenu(json)
            .then((res) => {
              if (res.data.code == 100000) {
                this.$message.success("删除成功");
                this.$emit("updata");
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch(() => {
              this.$message.warning("删除失败");
            });
        })
        .catch(() => {});
    },
    // 描述图文数据获取与验证列表
    gettitleData(data) {
      this.titleDataList = data;
      this.noPicList = this.titleDataList.filter((i) => {
        if (i.fileList.length == 0 && i.isOnline == "0") {
          return i;
        }
      });
      this.noTitleList = this.titleDataList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      this.noSortList = this.titleDataList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      this.noPicList2 = this.titleDataList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }
        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
    },
    // 描述图文数据获取与验证列表
    gettitleData2(data) {
      this.titleDataList = data;
      let childList = [];
      this.titleDataList.forEach((j) => {
        childList = [...j.child, ...childList];
      });
      this.noPicList = this.titleDataList.filter((i) => {
        if (i.fileList.length == 0 && i.isOnline == "0") {
          return i;
        }
      });
      let childFileList = childList.filter((i) => {
        if (i.fileList.length == 0 && i.isOnline == "0") {
          return i;
        }
      });
      this.noPicList = [...this.noPicList, ...childFileList];
      this.noTitleList = this.titleDataList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      let childTitleList = childList.filter((i) => {
        if (i.title == "") {
          return i;
        }
      });
      this.noTitleList = [...this.noTitleList, ...childTitleList];
      this.noSortList = this.titleDataList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      let childSortList = childList.filter((i) => {
        if (i.sort == "") {
          return i;
        }
      });
      this.noSortList = [...this.noSortList, ...childSortList];
      this.noPicList2 = this.titleDataList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }
        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      let childoutList = childList.filter((i) => {
        let arr = [];
        if (i.outFileList) {
          arr = i.outFileList.filter((t) => {
            if (t.url == "") {
              return t;
            }
          });
        }

        if (arr.length != 0 && i.isOnline == "1") {
          return i;
        }
      });
      this.noPicList2 = [...this.noPicList2, ...childoutList];
    },
    // 提交添加或修改
    submitData(form) {
      // 文字  字+图  中文文字必填
      if (this.setForm.attr == 33) {
        if (this.setForm.wordCn == "") {
          this.$message.warning("请填写文字内容");
          return false;
        }
      }
      // 名称  文字 字符验证
      if (
        this.setForm.name1.indexOf("--") != -1 ||
        this.setForm.name2.indexOf("--") != -1 ||
        this.setForm.name3.indexOf("--") != -1 ||
        this.setForm.name4.indexOf("--") != -1 ||
        this.setForm.name5.indexOf("--") != -1 ||
        this.setForm.name6.indexOf("--") != -1 ||
        this.setForm.wordCn.indexOf("--") != -1 ||
        this.setForm.wordEn.indexOf("--") != -1 ||
        this.setForm.wordJp.indexOf("--") != -1 ||
        this.setForm.wordFr.indexOf("--") != -1 ||
        this.setForm.wordSp.indexOf("--") != -1 ||
        this.setForm.wordKr.indexOf("--") != -1
      ) {
        this.$message.warning("不可以有英文字符--，请用其他字符替换");
        return false;
      }
      // 名称  文字 字符验证
      if (
        this.setForm.name1.indexOf("'") != -1 ||
        this.setForm.name2.indexOf("'") != -1 ||
        this.setForm.name3.indexOf("'") != -1 ||
        this.setForm.name4.indexOf("'") != -1 ||
        this.setForm.name5.indexOf("'") != -1 ||
        this.setForm.name6.indexOf("'") != -1 ||
        this.setForm.wordCn.indexOf("'") != -1 ||
        this.setForm.wordEn.indexOf("'") != -1 ||
        this.setForm.wordJp.indexOf("'") != -1 ||
        this.setForm.wordFr.indexOf("'") != -1 ||
        this.setForm.wordSp.indexOf("'") != -1 ||
        this.setForm.wordKr.indexOf("'") != -1
      ) {
        this.$message.warning("不可以有英文字符 '，请用其他字符替换");
        return false;
      }
      // 名称  文字 字符验证
      if (
        this.setForm.name1.indexOf('"') != -1 ||
        this.setForm.name2.indexOf('"') != -1 ||
        this.setForm.name3.indexOf('"') != -1 ||
        this.setForm.name4.indexOf('"') != -1 ||
        this.setForm.name5.indexOf('"') != -1 ||
        this.setForm.name6.indexOf('"') != -1 ||
        this.setForm.wordCn.indexOf('"') != -1 ||
        this.setForm.wordEn.indexOf('"') != -1 ||
        this.setForm.wordJp.indexOf('"') != -1 ||
        this.setForm.wordFr.indexOf('"') != -1 ||
        this.setForm.wordSp.indexOf('"') != -1 ||
        this.setForm.wordKr.indexOf('"') != -1
      ) {
        this.$message.warning('不可以有英文字符 "，请用其他字符替换');
        return false;
      }
      // 新建时 竖版图片必填    双图目录
      if (this.setForm.id == "" && this.setForm.attr == 46) {
        if (this.setForm.picVertical == "") {
          this.$message.warning("请上传竖版图片");
          return false;
        }
      }
      // 新建时 默认图片、焦点图片必填   音乐分类
      if (this.setForm.id == "" && this.setForm.attr == 49) {
        if (this.setForm.picVertical == "") {
          this.$message.warning("请上传默认图片");
          return false;
        }
        if (this.setForm.picHorizontal == "") {
          this.$message.warning("请上传焦点图片");
          return false;
        }
      } // 新建时 默认图片、焦点图片必填   音乐分类
      if (this.setForm.attr == 88 || this.setForm.attr == 78) {
        if (this.noPicList.length != 0) {
          this.$message.warning("请将素材内容补充完整");
          return false;
        }
        if (this.noPicList2.length != 0) {
          this.$message.warning("请将素材内容补充完整");
          return false;
        }
        if (this.noTitleList.length != 0) {
          this.$message.warning("请将标题补充完整");
          return false;
        }
        if (this.noSortList.length != 0) {
          this.$message.warning("请将排序补充完整");
          return false;
        }
        let arr = this.titleDataList.map((i) => {
          let list = [];
          if (i.isOnline == "0") {
            list = i.fileList.map((i) => {
              return i.url + "#" + i.md5;
            });
          } else {
            list = i.outFileList.map((i) => {
              return i.url;
            });
          }
          let obj = {
            title: i.title,
            desc: i.desc,
            sort: i.sort,
            isOnline: i.isOnline,
            piclist: list.join(","),
          };
          return obj;
        });
        this.setForm.titleList = arr;
      }
      if (this.setForm.attr == 71 || this.setForm.attr == 79) {
        if (this.noPicList.length != 0) {
          this.$message.warning("请将素材内容补充完整");
          return false;
        }
        if (this.noPicList2.length != 0) {
          this.$message.warning("请将素材内容补充完整");
          return false;
        }
        if (this.noTitleList.length != 0) {
          this.$message.warning("请将标题补充完整");
          return false;
        }
        if (this.noSortList.length != 0) {
          this.$message.warning("请将排序补充完整");
          return false;
        }
        let arr = this.titleDataList.map((i) => {
          let list = [];
          if (i.isOnline == "0") {
            list = i.fileList.map((i) => {
              return i.url + "#" + i.md5;
            });
          } else {
            list = i.outFileList.map((i) => {
              return i.url;
            });
          }
          let obj = {
            title: i.title,
            desc: i.desc,
            sort: i.sort,
            labelValue: i.labelValue,
            isOnline: i.isOnline,
            piclist: list.join(","),
          };

          let childArr = i.child.map((t) => {
            let list1 = [];
            if (t.isOnline == "0") {
              if (t.fileList.length > 0) {
                list1 = t.fileList.map((t) => {
                  return t.url + "#" + t.md5;
                });
              } else {
                list1 = [];
              }
            } else {
              if (t.outFileList.length > 0) {
                list1 = t.outFileList.map((t) => {
                  return t.url;
                });
              } else {
                list1 = [];
              }
            }
            let obj1 = {
              title: t.title,
              desc: t.desc,
              sort: t.sort,
              isOnline: t.isOnline,
              piclist: list1.join(","),
            };
            return obj1;
          });
          obj.child = childArr;
          return obj;
        });
        this.setForm.titleList = arr;
      }

      // 新建时 视频必填   视频
      if (this.setForm.attr == 37) {
        if (this.setForm.id == "" && this.setForm.videoRes == "") {
          this.$message.warning("请上传视频资源");
          return false;
        }
      }
      // 新建时 音频必填   歌曲
      if (this.setForm.attr == 41) {
        if (this.setForm.id == "" && this.setForm.musicRes == "") {
          this.$message.warning("请上传音频资源");
          return false;
        }
      }
      // 新建时  模块图片必填   跳转地址
      if (this.setForm.attr == 43) {
        if (this.setForm.id == "" && this.setForm.picHorizontal == "") {
          this.$message.warning("请上传跳转模块图片");
          return false;
        }
      }
      // 新建时  广告刊位必填   视频组  广告
      if (this.setForm.attr == 39 || this.setForm.attr == 48) {
        if (this.setForm.bljlm == 0) {
          this.$message.warning("请匹配对应广告刊位");
          return false;
        }
      }
      // 链接类型为包名时，链接地址必填
      if (this.setForm.bltype == 1 && this.setForm.bljlm == "") {
        this.$message.warning("链接类型为包名时，链接地址必填");
        return false;
      }
      if (this.setForm.parentname == "功能模块") {
        if (
          this.setForm.focus.picadd == "" ||
          this.setForm.default.picadd == ""
        ) {
          this.$message.warning("功能模块下功能，必需填写图标信息");
          return false;
        }
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.submiting = true;

              if (typeof this.setForm.bljlm != "string") {
                this.setForm.bljlm = this.setForm.bljlm.join(",");
              }
              if (typeof this.setForm.bljlm == "string") {
                this.setForm.bljlm = this.setForm.bljlm.trim()
              }
              let json = {
                datas: JSON.stringify(this.setForm),
                database: this.query.hotelName,
              };
              addMenu(json).then((res) => {
                if (res.data.code == 100000) {
                  this.$message.success("操作成功");
                  this.handleClose();
                  this.$emit("updata");
                  this.titleDataList = [];
                  this.noPicList = [];
                  this.noTitleList = [];
                  this.noSortList = [];
                  this.noPicList2 = [];
                } else {
                  this.$message.warning(res.data.msg);
                }
                this.submiting = false;
              });
              this.submiting = false;
            })
            .catch(() => {
              this.$message.warning("操作失败");
              this.submiting = false;
            });
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    // 表格数据获取
    getTableData(item) {
      this.tableData.tableLoading = true;
      const json = {
        id: this.setForm.id,
        attr: this.editAttr,
        opertion: "show",
        cid: "",
        database: this.query.hotelName,
      };
      showMenuContentDetail(json)
        .then((res) => {
          if (res.data.code == 100000) {
            this.tableData.tableList = res.data.data;
            //  数据字段处理
            if (res.data.data && res.data.data.length > 0) {
              this.tableData.tableList = this.tableData.tableList.map((i) => {
                if (i.ltype == 1) {
                  i.language = "中文";
                } else if (i.ltype == 2) {
                  i.language = "英文";
                } else if (i.ltype == 3) {
                  i.language = "日文";
                } else if (i.ltype == 4) {
                  i.language = "韩文";
                } else if (i.ltype == 5) {
                  i.language = "横板";
                } else if (i.ltype == 6) {
                  i.language = "竖版";
                } else if (i.ltype == 7) {
                  i.language = "焦点图标";
                } else if (i.ltype == 8) {
                  i.language = "默认图标";
                } else if (i.ltype == 10) {
                  i.language = "法文";
                } else if (i.ltype == 11) {
                  i.language = "西班牙文";
                }
                return i;
              });
            } else {
              this.tableData.tableList = [];
            }
            this.tableData.tableLoading = false;
          }
        })
        .catch((err) => {
          this.$message.warning("数据加载失败");
        });
    },
    // 打开部分节点详情界面
    openContentDialog(msg, item) {
      this.setForm.id = item.id;
      this.editAttr = item.attr;
      this.contentVisible = true;
      this.getTableData(item);
    },
    // 关闭详情界面
    contentClose() {
      this.setForm.id = "";
      this.contentVisible = false;
      this.tableData.tableList = [];
      this.tableData.limit = 10;
      this.tableData.page = 1;
      this.$refs.operationTable.changeTotal(0);
      this.$refs.operationTable.resetTable();
    },
    // 页面数据数
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableData("", s, p);
    },
    // 页码
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableData("", s, p);
    },
    // 选中项处理
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return i.id + "#" + i.type + "#" + i.mid;
      });
    },
    // 属性字段变化
    changeSelect() {
      this.setForm.wordCn = ""; //	中文文字
      this.setForm.wordEn = ""; //	英文文字
      this.setForm.wordJp = ""; //	日文文字
      this.setForm.wordKr = ""; //	韩文文字
      (this.setForm.wordSp = ""), //	西班牙文文字
        (this.setForm.wordFr = ""), //	法文文字
        // this.setForm.bljlm = ""; 链接地址：	跳转模块地址	广告刊位
        (this.setForm.picList_1 = ""); //中文图片
      this.setForm.picList_2 = ""; //英文图片
      this.setForm.picList_3 = ""; //日文图片
      this.setForm.picList_4 = ""; //韩文图片
      this.setForm.picList_5 = ""; //法文图片
      this.setForm.picList_6 = ""; //西班牙文图片
      this.setForm.picHorizontal = ""; // 竖版图	焦点图片	跳转模块图片
      this.setForm.picVertical = ""; //横板图	默认图片
      this.setForm.videoRes = ""; //视频资源
      this.setForm.musicRes = ""; //音频资源
      this.setForm.titleList = "";
      this.titleDataList = [];
    },
    // 表单界面展开
    openAddDialog(isStar, type, msg, item) {
      // 是否根目录
      if (isStar == "star") {
        this.menuTure = true;
      } else {
        this.menuTure = false;
      }
      // 子目录或修改
      if (type == "edit" || type == "addChidren") {
        this.setForm.id = msg;
        let json = {
          database: this.query.hotelName,
          id: this.setForm.id,
        };
        // 数据回显
        getEditMenuJson(json)
          .then((res) => {
            if (res.data.code == 100000) {
              // 修改回显所有返回字段
              if (type == "edit") {
                Object.assign(this.setForm, res.data.data);
                this.setForm.bltype = res.data.data.nowurl;
                this.setForm.name1 = res.data.data.name;
                this.setForm.name2 = res.data.data.nameEn;
                this.setForm.name3 = res.data.data.nameJp;
                this.setForm.name4 = res.data.data.nameHn;
                this.setForm.name5 = res.data.data.nameFr;
                this.setForm.name6 = res.data.data.nameSp;
                this.setForm.picHorizontal = res.data.data.picHorizontal; // 竖版图		跳转模块图片
                this.setForm.picVertical = res.data.data.picVertical; //横板图
                if (this.setForm.bltype == 4 || this.setForm.bltype == 5) {
                  this.setForm.bljlm = this.setForm.bljlm.split(",");
                }
                console.log(this.setForm, this.jumpOptions4);
                // 描述图文单独处理
                if (this.setForm.attr == 88 || this.setForm.attr == 78) {
                  this.titleDataList = [];
                  let arr = this.setForm.titleList;
                  this.titleDataList = arr.map((i) => {
                    let list = [{ url: "" }];
                    let list1 = [];
                    if (i.isOnline == "1") {
                      list = i.piclist.split(",").map((i) => {
                        let json = {
                          url: i,
                        };
                        return json;
                      });
                    } else {
                      list1 = i.piclist.split(",").map((i) => {
                        let json = {
                          url: i.split("#")[0],
                          md5: i.split("#")[1],
                        };
                        return json;
                      });
                    }
                    let obj = {
                      title: i.title,
                      desc: i.desc,
                      isOnline: i.isOnline ? String(i.isOnline) : "0",
                      sort: i.sort,
                      fileList: list1,
                      outFileList: list,
                    };
                    return obj;
                  });
                }
                if (this.setForm.attr == 71 || this.setForm.attr == 79) {
                  this.titleDataList = [];
                  let arr = this.setForm.titleList;
                  this.titleDataList = arr.map((i) => {
                    let list = [{ url: "" }];
                    let list1 = [];
                    if (i.isOnline == "1") {
                      list = i.piclist.split(",").map((i) => {
                        let json = {
                          url: i,
                        };
                        return json;
                      });
                    } else {
                      list1 = i.piclist.split(",").map((i) => {
                        let json = {
                          url: i.split("#")[0],
                          md5: i.split("#")[1],
                        };
                        return json;
                      });
                    }

                    let obj = {
                      title: i.title,
                      desc: i.desc,
                      isOnline: i.isOnline ? String(i.isOnline) : "0",
                      sort: i.sort,
                      labelValue: i.labelValue,
                      fileList: list1,
                      outFileList: list,
                    };

                    let childArr = i.child.map((t) => {
                      let list2 = [];
                      let list3 = [];
                      if (t.isOnline == "1") {
                        if (t.piclist.split(",").length > 0) {
                          list2 = t.piclist.split(",").map((j) => {
                            let json = {
                              url: j,
                            };
                            return json;
                          });
                        }
                      } else {
                        if (t.piclist.split(",").length > 0) {
                          list3 = t.piclist.split(",").map((j) => {
                            let json = {
                              url: j.split("#")[0],
                              md5: j.split("#")[1],
                            };
                            return json;
                          });
                        }
                      }
                      let obj1 = {
                        title: t.title,
                        desc: t.desc,
                        isOnline: t.isOnline ? String(t.isOnline) : "0",
                        sort: t.sort,
                        fileList: list3,
                        outFileList: list2,
                      };
                      return obj1;
                    });
                    obj.child = childArr;
                    return obj;
                  });
                  console.log(this.titleDataList);
                }
              }
              // 添加子目录回显节点名称，清楚id
              if (type == "addChidren") {
                this.setForm.id = "";
                this.setForm.parentname = item.mname;
              }
            } else {
              this.$message.warning(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.warning("数据获取失败");
          });
      }
      this.dialogVisible = true;
    },
    linkTypeChange() {
      this.setForm.bljlm = "";
    },
    // 获取属性列表
    getAttrList() {
      getFunctionName({ database: this.query.hotelName, type: 4 }).then(
        (res) => {
          if (res.data.code == 100000) {
            this.jumpOptions = res.data.data;
            console.log(this.jumpOptions);
          }
        }
      );
      getFunctionName({ database: this.query.hotelName, type: 5 }).then(
        (res) => {
          if (res.data.code == 100000) {
            this.jumpOptions3 = res.data.data;
            console.log(this.jumpOptions3);
          }
        }
      );
      getMenuAttrList().then((res) => {
        if (res.data.code == 100000) {
          this.attrListTwo = res.data.data;
        }
      });
      const json = {
        database: this.query.hotelName,
      };
      getAdsModule(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data) {
            this.adsList = res.data.data;
          } else {
            this.adsList = [];
          }
        }
      });
      getMenuModule(json).then((res) => {
        if (res.data.code == 100000) {
          if (res.data.data) {
            this.jumpList = res.data.data;
          } else {
            this.jumpList = [];
          }
        }
      });
    },
    // 关闭表单界面
    handleClose() {
      this.dialogVisible = false;
      this.setForm = {
        name1: "", // 中文名称
        name2: "", // 英文名称
        name3: "", // 日文名称
        name4: "", // 韩文名称
        name5: "", // 法文名称
        name6: "", // 西班牙文名称
        wordCn: "", //	中文文字
        wordEn: "", //	英文文字
        wordJp: "", //	日文文字
        wordKr: "", //	韩文文字
        wordSp: "", //	西班牙文文字
        wordFr: "", //	法文文字
        attr: "", // 属性
        parentname: "", //	上级名称
        morder: "", //	排序号：
        menu_pic: "", //	备填项
        bljlm: "", //链接地址：	跳转模块地址	广告刊位
        bltype: "", //	链接类型
        level: "", //level
        id: "", //	？
        owner: "", //	拥有者
        picList_1: "", //中文图片
        picList_2: "", //英文图片
        picList_3: "", //日文图片
        picList_4: "", //韩文图片
        picList_5: "", //法文图片
        picList_6: "", //西班牙图片
        picHorizontal: "", // 竖版图	焦点图片	跳转模块图片
        picVertical: "", //横板图	默认图片
        videoRes: "", //视频资源
        musicRes: "", //音频资源
        titleList: "",
        focus: {
          id: "",
          md5: "",
          picadd: "",
        },
        default: {
          id: "",
          md5: "",
          picadd: "",
        },
      };
      this.titleDataList = [];
      this.noPicList = [];
      this.noTitleList = [];
      this.noSortList = [];
      this.noPicList2 = [];
    },
    // 获取批量图片信息
    getPicList(val, num) {
      let arr = val.map((i) => {
        return i.url;
      });
      if (num == "1") {
        this.setForm.picList_1 = arr.join(",");
      } else if (num == "2") {
        this.setForm.picList_2 = arr.join(",");
      } else if (num == "3") {
        this.setForm.picList_3 = arr.join(",");
      } else if (num == "4") {
        this.setForm.picList_4 = arr.join(",");
      } else if (num == "5") {
        this.setForm.picList_5 = arr.join(",");
      } else if (num == "6") {
        this.setForm.picList_6 = arr.join(",");
      }
    },
    // 展示批量上传界面
    openUPload(num) {
      // this.$refs.uploadPicText.open(this.setForm.picList_1, num)
      if (num == "1") {
        this.$refs.uploadMorePic.open(this.setForm.picList_1, num);
      } else if (num == "2") {
        this.$refs.uploadMorePic.open(this.setForm.picList_2, num);
      } else if (num == "3") {
        this.$refs.uploadMorePic.open(this.setForm.picList_3, num);
      } else if (num == "4") {
        this.$refs.uploadMorePic.open(this.setForm.picList_4, num);
      } else if (num == "5") {
        this.$refs.uploadMorePic.open(this.setForm.picList_5, num);
      } else if (num == "6") {
        this.$refs.uploadMorePic.open(this.setForm.picList_6, num);
      }
    },
  },
  watch: {
    // 渲染数据监听
    content: {
      handler: function (newVal) {
        if (this.content[0]) {
          this.activeName = String(this.content[0].id);
        }
      },
      deep: true,
      immediate: true,
    },
    // 酒店信息监听
    query: {
      handler: function (newVal) {
        this.query = newVal;
      },
      deep: true,
      immediate: true,
    },
    menuList: {
      handler: function (newVal) {
        this.menuAllList2 = newVal;
        this.menuAllList = newVal;
      },
      deep: true,
      immediate: true,
    },
    jumpOptionsTwo: {
      handler: function (newVal) {
        this.jumpOptions2 = newVal;
        this.jumpOptions = newVal;
      },
      deep: true,
      immediate: true,
    },
    jumpOptionsFour: {
      handler: function (newVal) {
        this.jumpOptions4 = newVal;
        this.jumpOptions3 = newVal;
      },
      deep: true,
      immediate: true,
    },
    // 属性字段监听
    attrList: {
      handler: function (newVal) {
        if (this.first == "no") {
          this.attrListTwo = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    // 属性字段监听
    adsListTwo: {
      handler: function (newVal) {
        if (this.first == "no") {
          this.adsList = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    // 属性字段监听
    jumpListTwo: {
      handler: function (newVal) {
        if (this.first == "no") {
          this.jumpList = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.collapseContent {
  padding: 0 0 0 15px;
}
::v-deep .el-collapse-item__header {
  background: #f2f2f2;
  border-bottom: 1px solid #eee;

  box-sizing: border-box;
}

.el-collapse-item {
  border-left: 1px solid #eee;
}
.firstBorder {
  border-right: 1px solid #eee;
}
.titleText {
  margin-left: 30px;
}
.marginLeft {
  margin-left: 20px;
}
</style>
