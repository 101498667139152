<template>
  <div>
    <!-- 按钮功能 -->
    <div class="btnRow">
      <el-button type="primary" class="largeBtn" @click="versionSetting('版本生成')">版本生成</el-button>
      <el-button type="primary" class="largeBtn" @click="versionSetting('设备清空')">设备清空</el-button>
      <el-button type="primary" class="largeBtn" @click="versionSetting('设备迁移')">设备迁移</el-button>
    </div>
    <!-- 按钮功能 -->
    <!-- 表格信息 -->
    <operationTable ref="operationTable" :tableData="tableData" @defaultsize="changeSize" @defaultpage="changeCurrent" @defaultcheck="changeSelection" />
    <!-- 表格信息 -->
    <!-- 功能表单 -->
    <el-dialog :title="title" :visible.sync="contentVisible" width="60%" :before-close="contentClose" :append-to-body="true">
      <el-form ref="versionForm" :rules="rules" :model="versionForm" label-width="100px">
        <el-form-item v-if="!editId" label="更新说明" prop='upDesc'>
          <el-input v-model="versionForm.upDesc" :readonly="title=='设备迁移'||title=='设备清空'"></el-input>
        </el-form-item>
        <el-form-item v-if="!editId" label="更新模式" prop='upMeth'>
          <el-select v-model="versionForm.upMeth" placeholder="请选择更新模式" class="hotelBrand fullSelect " filterable collapse-tags clearable @change="changeMeth">
            <el-option label="全部房间" value="all">
            </el-option>
            <el-option label="部分房间" value="part">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item v-if="title=='设备迁移'" label="是否清空" prop='is_clear'>
          <el-select v-model="versionForm.is_clear" placeholder="选择是否清空" class="hotelBrand fullSelect " filterable collapse-tags clearable >
            <el-option label="清空" value="1">
            </el-option>
            <el-option label="不清空" value="2">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="versionForm.upMeth=='part'||editId" label="部分房间" prop='room'>
          <el-input :readonly="true" v-model="versionForm.room"></el-input>
          <el-button type="primary" class="largeBtn marginLeft" @click="choiceRoomMeth">选择房间</el-button>
        </el-form-item>
        <el-form-item v-if="title=='设备迁移'&&!editId" label="服务器地址" prop='serverAdd'>
          <el-input v-model="versionForm.serverAdd"></el-input>
        </el-form-item>
        <el-form-item v-if="title=='设备迁移'&&!editId" label="后台名字" prop='serverName'>
          <el-input v-model="versionForm.serverName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="optTrue('versionForm')" :loading='trueLoading'>确 定</el-button>
        <el-button @click="contentClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 功能表单 -->
    <!-- 选择房间 -->
    <el-dialog title="选择房间" :visible.sync="roomVisible" width="60%" :append-to-body="true">
      <choose-hotel-room style="margin-top:20px" ref="chooseHotelRoom" :queryRoom="queryRoom" @sendroom='getRoom' @close="closeRoom" :editRoom="editRoom" :editId="editId"></choose-hotel-room>
      <span slot="footer" class="dialog-footer">
        <el-button class="trueBtn" type="primary" @click="checkTrue">确 定</el-button>
        <el-button class="cancelBtn" @click="checkCancel">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择房间 -->
    <!-- 操作详情界面 -->
    <el-dialog title="详情" :visible.sync="infoVisible" width="40%" :append-to-body="true">
      <div v-loading="infoLoading">
        <el-row class="tableRow  tableRowHeader" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">状态</el-col>
          <el-col class="tableCell tableRightCell" :span="12">房间号</el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">未执行({{infoData.diff.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.diff.join(',')" placement="top"> -->
            <span>{{infoData.diff.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已获取({{infoData.state1.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state1.join(',')" placement="top"> -->
            <span>{{infoData.state1.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已执行({{infoData.state2.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state2.join(',')" placement="top"> -->
            <span>{{infoData.state2.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
        <el-row class="tableRow tableRowFooter" :span="24">
          <el-col class="tableCell tableLeftCell" :span="12">已完成({{infoData.state3.length}}间)</el-col>
          <el-col class="tableCell tableRightCell" :span="12">
            <!-- <el-tooltip class="item" popper-class="tooltipItem" effect="dark" :content="infoData.state3.join(',')" placement="top"> -->
            <span>{{infoData.state3.join(",")}}</span>
            <!-- </el-tooltip> -->
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelBtn" @click="infoVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getVersionControl,
  addVersion,
  editVersion,
  getFinishUpdateRooms,
} from "../../api/version";
import chooseHotelRoom from "../common/chooseHotelRoom.vue";
export default {
  name: "versionControl",
  props: ["query"],
  components: {
    chooseHotelRoom,
  },
  data() {
    return {
      auth: {},
      infoVisible: false,
      roomVisible: false,
      contentVisible: false,
      // 表格配置
      tableData: {
        tableList: [],
        checked: false, //多选
        tableLoading: false, //加载
        page: 1,
        limit: 10,
        props: [
          {
            label: "编号",
            prop: "id",
            minWidth: "80",
            type: "text",
          },
          {
            label: "更新说明",
            prop: "intro",
            minWidth: "200",
            type: "text",
            showTooltip: true,
          },
          {
            label: "数据版本",
            prop: "dataVersion",
            minWidth: "80",
            type: "text",
          },
          {
            label: "更新模式",
            prop: "module",
            minWidth: "80",
            type: "text",
          },
          {
            label: "房间",
            prop: "roomStr",
            minWidth: "80",
            type: "text",
          },
          {
            label: "时间",
            prop: "stime",
            minWidth: "200",
            type: "text",
          },
          {
            label: "操作者",
            prop: "uname",
            minWidth: "96",
            type: "text",
          },
          {
            label: "操作",
            minWidth: "150",
            type: "button",
            prop: "num",
            // 详情
            info: (item) => {
              const json = {
                id: item.id,
                database: this.query.hotelName,
                ipaddr: this.query.ipaddr,
              };
              this.infoLoading = true;
              getFinishUpdateRooms(json)
                .then((res) => {
                  if (res.data.code == 100000) {
                    this.infoData = res.data.data;
                  } else {
                    this.$message.warning(res.data.msg);
                  }
                  this.infoLoading = false;
                })
                .catch((err) => {
                  this.$message.warning("数据加载失败");
                  this.infoLoading = false;
                });
              this.infoVisible = true;
            },
            // 展开部分添加界面
            addOther: (item) => {
              this.editId = item.id;
              this.editRoom = item.roomStr;
              this.versionSetting("部分房间");
            },
            // 全部添加按钮
            addAll: (item) => {
              this.$confirm("是否确认全部执行操作？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  const json = {
                    id: item.id,
                    module: "all",
                    database: this.query.hotelName,
                    ipaddr: this.query.ipaddr,
                  };
                  this.tableData.tableLoading = true;
                  editVersion(json)
                    .then((res) => {
                      if (res.data.code == 100000) {
                        this.$message.success("执行成功");
                        this.tableData.page = 1;
                        this.$refs.operationTable.resetTable(
                          this.tableData.limit,
                          this.tableData.page
                        );
                        this.getTableList(
                          this.query.hotelName,
                          this.tableData.limit,
                          this.tableData.page
                        );
                        this.contentClose();
                      } else {
                        this.$message.warning(res.data.msg);
                        this.tableData.tableLoading = false;
                      }
                    })
                    .catch((err) => {
                      this.$message.warning("执行失败");
                      this.tableData.tableLoading = false;
                    });
                })
                .catch(() => {});
            },
          },
        ],
      },
      trueLoading: false,
      editId: "",
      editRoom: null,
      checkList: [],
      title: "版本生成",
      versionForm: {
        serverName: "",
        serverAdd: "jd.ctlife.tv:8000",
        room: "",
        upMeth: "part",
        is_clear:"1",
        upDesc: "",
        acttype: "",
      },
      rules: {
        is_clear: [
          { required: true, message: "请选择是否清空", trigger: "blur" },
        ],
        serverName: [
          { required: true, message: "请输入后台名称", trigger: "blur" },
        ],
        serverAdd: [
          { required: true, message: "请输入服务器地址", trigger: "blur" },
        ],
        room: [{ required: true, message: "请选择更新房间", trigger: "blur" }],
        upMeth: [
          { required: true, message: "请选择更新模式", trigger: "blur" },
        ],
        upDesc: [
          { required: true, message: "请输入更新说明", trigger: "blur" },
        ],
      },
      queryRoom: {},
      infoData: {
        diff: [],
        state1: [],
        state2: [],
        state3: [],
      },
      infoLoading: false,
    };
  },
  watch: {
    // 酒店信息监听
    query: {
      handler: function (newVal) {
        this.query = newVal;
        this.getTableList(
          this.query.hotelName,
          this.tableData.limit,
          this.tableData.page
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 改变发布方式
    changeMeth() {
      this.versionForm.room = "";
    },
    // 确认当前操作
    optTrue(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          // 确认
          this.$confirm("是否确认当前操作？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // 按钮功能
              if (this.title != "部分房间") {
                // 参数
                const json = {
                  intro: this.versionForm.upDesc,
                  acttype: this.versionForm.acttype,
                  module: this.versionForm.upMeth,
                  is_clear:this.versionForm.is_clear,
                  roomStr:
                    this.versionForm.upMeth == "all"
                      ? ""
                      : this.getString(this.versionForm.room),
                  database: this.query.hotelName,
                  ipaddr: this.query.ipaddr,
                  serverAddr: this.versionForm.serverAdd,
                  serverName: this.versionForm.serverName,
                  username: this.auth.user_name,
                };
                this.trueLoading = true;
                // 接口
                addVersion(json)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      this.$message.success(this.title + "成功");
                      // 刷新并重置 分页数据
                      this.tableData.page = 1;
                      this.$refs.operationTable.resetTable(
                        this.tableData.limit,
                        this.tableData.page
                      );
                      this.getTableList(
                        this.query.hotelName,
                        this.tableData.limit,
                        this.tableData.page
                      );
                      this.contentClose();
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                    this.trueLoading = false;
                  })
                  .catch((err) => {
                    this.$message.warning(this.title + "失败");
                  });
              } else {
                // 操作栏功能
                // 参数
                const json = {
                  id: this.editId,
                  module: this.versionForm.upMeth,
                  roomStr:
                    this.versionForm.upMeth == "all"
                      ? ""
                      : this.getString(this.versionForm.room),
                  database: this.query.hotelName,
                  ipaddr: this.query.ipaddr,
                };
                this.trueLoading = true;
                editVersion(json)
                  .then((res) => {
                    if (res.data.code == 100000) {
                      // 刷新并重置 分页数据
                      this.$message.success("部分房间更新成功");
                      this.tableData.page = 1;
                      this.$refs.operationTable.resetTable(
                        this.tableData.limit,
                        this.tableData.page
                      );
                      this.getTableList(
                        this.query.hotelName,
                        this.tableData.limit,
                        this.tableData.page
                      );
                      this.contentClose();
                    } else {
                      this.$message.warning(res.data.msg);
                    }
                    this.trueLoading = false;
                  })
                  .catch((err) => {
                    this.$message.warning("部分房间更新失败");
                  });
              }
            })
            .catch(() => {});
        } else {
          this.$message.warning("请将表单信息补充完整");
          return false;
        }
      });
    },
    // 字段拼接
    getString(list) {
      let arr = list.split(",");
      let str = "";
      for (let i = 0; i < arr.length; i++) {
        str += "|" + arr[i];
      }
      return str;
    },
    // 获取表格数据
    getTableList(dataBase, s, p) {
      this.tableData.tableLoading = true;
      const json = {
        page: p,
        limit: s,
        database: dataBase,
      };
      getVersionControl(json)
        .then((res) => {
          if (res.data.code == 100000) {
            if (res.data.data.items && res.data.data.items.length > 0) {
              // 数据赋值
              this.tableData.tableList = res.data.data.items;
              // 总数传递
              this.$refs.operationTable.changeTotal(
                Number(res.data.data.total)
              );
            } else {
              // 数据赋值
              this.tableData.tableList = [];
              // 总数传递
              this.$refs.operationTable.changeTotal(0);
            }
          } else {
            this.$message.warning(res.data.msg);
          }
          this.tableData.tableLoading = false;
        })
        .catch((err) => {
          this.$message.warning("数据获取失败");
          this.tableData.tableLoading = false;
        });
    },
    // 打开选择房间界面s
    choiceRoomMeth() {
      this.queryRoom = {
        id: this.query.id,
        roomStr: this.versionForm.room,
        database: this.query.hotelName,
        hotel_contract: this.query.hotel_contract,
      };
      this.roomVisible = true;
    },
    // 房间选择确认
    checkTrue() {
      this.$refs.chooseHotelRoom.submitMeth();
    },
    // 房间选择取消
    checkCancel() {
      this.$refs.chooseHotelRoom.showDrawer(false);
    },
    // 获取房间信息
    getRoom(val) {
      this.versionForm.room = val;
      this.roomVisible = false;
    },
    // 关闭房间选择界面
    closeRoom() {
      this.roomVisible = false;
    },
    // 关闭 取消 当前操作  清空数据
    contentClose() {
      this.versionForm = {
        serverName: "",
        serverAdd: "jd.ctlife.tv:8000",
        room: "",
        upMeth: "part",
        upDesc: "",
        acttype: "",
      };
      this.editRoom = null;
      this.editId = "";
      this.contentVisible = false;
    },
    // 展示功能表单
    versionSetting(text) {
      this.title = text;
      // 功能信息配置
      if (this.title == "版本生成") {
        this.versionForm.upDesc = "";
        this.versionForm.acttype = "getVersionContent";
      } else if (this.title == "设备清空") {
        this.versionForm.upDesc = "设备清空";
        this.versionForm.acttype = "CLEAR";
      } else if (this.title == "设备迁移") {
        this.versionForm.upDesc = "设备迁移";
        this.versionForm.acttype = "transfer";
      } else if (this.title == "部分房间") {
        this.versionForm.upMeth = "part";
      }
      this.contentVisible = true;
    },
    // limit变化
    changeSize(s, p) {
      this.tableData.limit = s;
      this.getTableList(this.query.hotelName, s, p);
    },
    // page 变化
    changeCurrent(s, p) {
      this.tableData.page = p;
      this.getTableList(this.query.hotelName, s, p);
    },
    // 选择列表项
    changeSelection(val) {
      this.checkList = val.map((i) => {
        return id;
      });
    },
  },
  mounted() {
    this.auth = JSON.parse(sessionStorage.getItem("auth"));
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.tableRow {
  font-size: 14px;
  min-height: 50px;
  border: 1px solid #ddd;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableRowHeader {
  background: #f8fbff;
  color: #606266;
}
.tableRowFooter {
  border-bottom: 1px solid #ddd;
}
.tableCell {
  text-align: center;
  line-height: 30px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}
.tableLeftCell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableRightCell {
  border-left: 1px solid #ddd;
}
.tableCell span {
  display: block;
  width: 90%;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.el-tooltip.aaa {
  color: red;
  width: 400px !important;
}
</style>
