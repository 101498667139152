import { render, staticRenderFns } from "./DataShowBottom.vue?vue&type=template&id=7663bac8&scoped=true&"
import script from "./DataShowBottom.vue?vue&type=script&lang=js&"
export * from "./DataShowBottom.vue?vue&type=script&lang=js&"
import style0 from "./DataShowBottom.vue?vue&type=style&index=0&id=7663bac8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7663bac8",
  null
  
)

export default component.exports