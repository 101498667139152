import { render, staticRenderFns } from "./Del.vue?vue&type=template&id=6f4c8c85&scoped=true&"
import script from "./Del.vue?vue&type=script&lang=js&"
export * from "./Del.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f4c8c85",
  null
  
)

export default component.exports