<template>
  <div>
    <div class="tableBox">
      <el-table ref="multipleTable" :header-cell-style="headerStyle" :data="tableData.tableList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" v-loading="tableData.tableLoading">
        <el-table-column type="selection" width="55" v-if="tableData.checked"></el-table-column>
        <el-table-column :label="item.label" :min-width="item.minWidth" v-for="(item,index) in tableData.props" :show-overflow-tooltip="!item.showTooltip" :key="index">
          <template slot-scope="scope">
            <template v-if="item.isSlot">
              <slot name="operation" :row="scope.row"></slot>
              <slot name="publish_type" v-if="item.prop=='publish_type'" :row="scope.row">插槽</slot>
              <slot name="publish_status" v-if="item.prop=='publish_status'" :row="scope.row">插槽</slot>
              <slot name="play" v-if="item.prop=='state'" :row="scope.row">插槽</slot>
              <slot name="open" v-if="item.prop=='isOpen'" :row="scope.row">插槽</slot>
              <slot name="add" v-if="item.prop=='isConcatRoom'" :row="scope.row">插槽</slot>
              <!-- 父组件接受数据 -->
              <!-- <template 
                slot="operation" 
                slot-scope="{row}" >
                <a :href="row.logUrl" target="_blank" >{{row.logUrl}}</a>
                </template> -->
            </template>
            <template v-else>
              <el-button v-if="item.type=='button'&&item.edit" :disabled="scope.row.isEditDisabled" @click="item.edit(scope.row)" type="text" size="small">修改</el-button>
              <el-button v-if="item.type=='button'&&item.info" @click="item.info(scope.row)" type="text" size="small">详情</el-button>
              <el-button v-if="item.type=='button'&&item.result" @click="item.result(scope.row)" type="text" size="small">发布追踪</el-button>

              <el-button v-if="item.type=='button'&&item.reset" :disabled="(scope.row.hotelcount&&scope.row.updatehotelcount)&&(scope.row.hotelcount==scope.row.updatehotelcount)" @click="item.reset(scope.row)" type="text" size="small">重发</el-button>
              <el-button v-if="item.type=='button'&&item.load" @click="item.load(scope.row)" type="text" size="small">刷新</el-button>

              <el-button v-if="item.type=='button'&&item.addHotel" @click="item.addHotel(scope.row)" type="text" size="small">+酒店</el-button>
              <el-button v-if="item.type=='button'&&item.addRoom" @click="item.addRoom(scope.row)" type="text" size="small">新增</el-button>
              <el-button v-if="item.type=='button'&&item.downRoom" :disabled='item.needDisabled&&scope.row.publish_status==3' :style="{color:item.needDisabled&&scope.row.publish_status==3?'gray':'red'}" @click="item.downRoom(scope.row)" type="text" size="small">下架</el-button>
              <el-button v-if="item.type=='button'&&item.import" @click="item.import(scope.row)" type="text" size="small">导出</el-button>
              <el-button v-if="item.type=='button'&&item.dateLose" @click="item.dateLose(scope.row)" type="text" size="small">过期</el-button>
              <el-button v-if="item.type=='button'&&item.down" :disabled="!scope.row.url" @click="item.down(scope.row)" type="text" size="small">下载</el-button>
              <el-button v-if="item.type=='button'&&item.log" :disabled="!scope.row.url" @click="item.log(scope.row)" type="text" size="small">更新日志</el-button>
              <el-button v-if="item.type=='button'&&item.addOther&&scope.row.module!='all'&&scope.row.intro!='设备清空'&&scope.row.intro!='设备迁移'" @click="item.addOther(scope.row)" type="text" size="small">+部分</el-button>
              <el-button v-if="item.type=='button'&&item.addAll&&scope.row.module!='all'&&scope.row.intro!='设备清空'&&scope.row.intro!='设备迁移'" @click="item.addAll(scope.row)" type="text" size="small">+全部</el-button>
              <el-button v-if="item.type=='button'&&item.resetDevice" :disabled="scope.row.alive == '0'" @click="item.resetDevice(scope.row)" type="text" size="small">复位</el-button>
              <el-button v-if="item.type=='button'&&item.del" style="color:red" @click="item.del(scope.row)" type="text" size="small">删除</el-button>
              <el-button v-if="item.type=='button'&&item.deal&& scope.row.state == 0" @click="item.deal(scope.row)" type="text" size="small">处理</el-button>
              <span v-if="item.type=='text'">{{scope.row[item.prop]}}</span>
              <img v-if="item.type=='pic'" :src="scope.row[item.prop]" alt style="max-width:100px;max-height:100px" />
              <video v-if="item.type=='video'" :src="scope.row[item.prop]" style="max-width:100px;max-height:100px"></video>
            </template>

          </template>

        </el-table-column>

      </el-table>
    </div>
    <div class="block pageBox" v-if="!(tableData.noPagination) && tableData.tableList.length > 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="table.page" :page-sizes="[5, ,10,20, 50, 100, 150, 200, 250, 300]" :page-size="table.limit" layout="total, sizes, prev, pager, next, jumper" :total="table.total"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "operationTable",
  props: {
    tableData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      headerStyle: {
        background: "#f8fbff",
        color: "#606266",
      },
      table: {
        total: 0,
        page: 1,
        limit: 10,
      },
      multipleSelection: [],
    };
  },
  watch: {
    tableData: {
      handler: function (newVal) {
        this.tableData = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit("defaultcheck", this.multipleSelection);
    },
    // 条目
    handleSizeChange(val) {
      this.table.limit = val;
      this.$emit("defaultsize", this.table.limit, this.table.page);
    },
    // 页码
    handleCurrentChange(val) {
      this.table.page = val;
      this.$emit("defaultpage", this.table.limit, this.table.page);
    },
    // 总数修改
    changeTotal(val) {
      this.table.total = val;
    },
    // 重置分页
    resetTable(s, p) {
      this.table.total = 0;
      this.table.limit = s ? s : 10;
      this.table.page = p ? p : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}
</style>
