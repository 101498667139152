import { render, staticRenderFns } from "./FoldTopSearch.vue?vue&type=template&id=227d058d&scoped=true&"
import script from "./FoldTopSearch.vue?vue&type=script&lang=js&"
export * from "./FoldTopSearch.vue?vue&type=script&lang=js&"
import style0 from "./FoldTopSearch.vue?vue&type=style&index=0&id=227d058d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227d058d",
  null
  
)

export default component.exports