import { render, staticRenderFns } from "./EditHomeEhotelRightText.vue?vue&type=template&id=bba35c2a&scoped=true&"
import script from "./EditHomeEhotelRightText.vue?vue&type=script&lang=js&"
export * from "./EditHomeEhotelRightText.vue?vue&type=script&lang=js&"
import style0 from "./EditHomeEhotelRightText.vue?vue&type=style&index=0&id=bba35c2a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba35c2a",
  null
  
)

export default component.exports